import React from "react";
import { Box, Button, Typography } from "@mui/material";
import Lottie from "react-lottie";
import animationData from "../../components/lotties/PaymentSuccessful.json";
import { Link } from "react-router-dom";
import { theme } from "../../theme";

export const PaymentSuccess = () => {
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <>
      <div className="payment-container">
        <div className="flexContainer">
          <Box
            p={3}
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              width: { xs: 340, sm: 390, md: 420, lg: 520 },
              height: { xs: 340, sm: 390, md: 420, lg: 520 },
              border: "1px solid #009974",
              backgroundColor: "#fff",
              borderRadius: "8px",
              margin: "auto",
            }}>
            <Lottie options={defaultOptions} />
            {/* <Typography variant="h6" textAlign="center">
            payment successful
            </Typography> */}
            <Typography
              variant="body"
              textAlign="center"
              sx={{
                color: "gray",
                fontSize: { xs: "13px", sm: "14px", md: "16px" },
              }}>
              Your payment was successful! Go to the dashboard
            </Typography>
            <Link to="/my-courses" style={{ margin: "auto" }}>
              <Button
                type="submit"
                variant="contained"
                size="small"
                sx={{
                  marginBottom: "15px",
                  marginTop: "20px",
                  "&.MuiButtonBase-root": { borderRadius: "5px" },
                  mx: "auto",
                  backgroundColor: theme.palette.secondary.main,
                  "&:hover": {
                    backgroundColor: theme.palette.secondary.main,
                  },
                }}>
                Go to My-Courses
              </Button>
            </Link>
          </Box>
        </div>
      </div>
    </>
  );
};
