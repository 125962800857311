import { Box, Button, Grid, Paper, Typography,styled} from "@mui/material";
import React from "react";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import ArrowRight from '@mui/icons-material/ArrowRight';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import Home from '@mui/icons-material/Home';
import Settings from '@mui/icons-material/Settings';
import People from '@mui/icons-material/People';
import PermMedia from '@mui/icons-material/PermMedia';
import Dns from '@mui/icons-material/Dns';
import Public from '@mui/icons-material/Public';

const Container = styled(Box)(() => ({
backgroundColor:'rgb(5, 30, 52)',
  width: "100%",
  paddingRight:"1rem",
  paddingLeft:"1rem",
  marginRight:'auto',
  marginLeft:'auto'
}));

const data = [
    { icon: <People />, label: 'Authentication' },
    { icon: <Dns />, label: 'Database' },
    { icon: <PermMedia />, label: 'Storage' },
    { icon: <Public />, label: 'Hosting' },
    { icon: <People />, label: 'Authentication' },
    { icon: <Dns />, label: 'Database' },
    { icon: <PermMedia />, label: 'Storage' },
    { icon: <Public />, label: 'Hosting' },
    { icon: <People />, label: 'Authentication' },
    { icon: <People />, label: 'Authentication' },
  
  ];

  const FireNav = styled(List)({
    '& .MuiListItemButton-root': {
      paddingLeft: 24,
      paddingRight: 24,
    },
    '& .MuiListItemIcon-root': {
      minWidth: 0,
      marginRight: 16,
    },
    '& .MuiSvgIcon-root': {
      fontSize: 20,
    },
  });

  const Content=styled(Typography)(()=>({
    color:'whitesmoke',
    margin:'20px 0',
    fontSize:'24px'
  }));

const SubTitle=styled(Typography)(()=>({
    color:'whitesmoke',
    margin:'10px 0',
    fontSize:'17px'
}))

const AllDetails = () => {
    const [open, setOpen] = React.useState(true);
  return (
    <Container>
      <Grid container spacing={2} sx={{p:"10px 150px"}}>
        <Grid item xs={12} sm={12} md={12} lg={8}>
            <Box sx={{mt:5}}>
          <Typography variant="h4" color='#ffff'>All-Access Subscription</Typography>
          <Content>
            Become the best coder you can be with unlimited access to all the
            existing and future courses
          </Content>
          <SubTitle>4.7 Stars 2780 reviews</SubTitle>
          <SubTitle>Subscribe now to access all courses. Cancel anytime!</SubTitle>
          <Button variant="contained" sx={{borderRadius:"20px"}}>Buy This Course For $149</Button>
         
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={4} display='flex' justifyContent='flex-end'>
            <Box sx={{backgroundColor:'rgb(5, 30, 52)' ,border:"1px solid grey",width:{xs:"100%",sm:'100%',md:'100%',lg:250},borderRadius:'5px'}}>
          <FireNav>
            <Box>
              <ListItemButton
                alignItems="flex-start"
                onClick={() => setOpen(!open)}
                sx={{
                  px: 3,
                  pt: 2.5,
                //   pb: open ? 0 : 2.5,
                }}
              >
                <ListItemText
                  primary="COURSE OVERVIEW"
                  primaryTypographyProps={{
                    fontSize: 20,
                    lineHeight: '20px',
                    mb: '10px',
                    color:"#ffff"
                  }}
                  sx={{ my: 0 }}
                />
              </ListItemButton>
              {
                data.map((item) => (
                  <ListItemButton
                    key={item.label}
                    sx={{ py: 0, minHeight: 35, color: 'rgba(255,255,255,.8)' }}
                  >
                    <ListItemIcon sx={{ color: 'inherit' }}>
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText
                      primary={item.label}
                      primaryTypographyProps={{ fontSize: 16, fontWeight: 'medium' }}
                    />
                  </ListItemButton>
                ))}
            </Box>
          </FireNav>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default AllDetails;



