import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import {
  Box,
  CardActionArea,
  Container,
  Grid,
  Button,
  styled,
  Tooltip,
  Dialog,
  DialogActions,
} from "@mui/material";
import AccessTimeRoundedIcon from "@mui/icons-material/AccessTimeRounded";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import Rating from "@mui/material/Rating";
import PropTypes from "prop-types";
import LinearProgress from "@mui/material/LinearProgress";
import { useNavigate } from "react-router-dom";
import { useEffect, useState, useContext } from "react";
import { get, post, del } from "../../../api/apiMethods";
import { urls } from "../../../api/urlsContants";
import courseImage from "../../../assets/Images/courseimg.png";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import UserContext from "../../../context/UserContext";
import { theme } from "../../../theme";
const style = {
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
};

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ mt: 1 }}>
      <Box sx={{ width: "100%", mr: 1 }} color={theme.palette.primary.dark}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <ProgressBarBox>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%Completed`}</Typography>
      </ProgressBarBox>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
};

const Design = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontSize: "12px",
}));
const Title = styled(Typography)(({ theme }) => ({
  textTransform: "uppercase",
  fontSize: "1rem",
  fontWeight: "bold",
  marginTop: "2%",
  color: theme.palette.primary.main,
  display: "-webkit-box",
  margin: "2px 0",
  WebkitLineClamp: 1,
  overflow: "hidden",
  WebkitBoxOrient: "vertical",
}));
const Name = styled(Box)(() => ({
  fontWeight: 550,
  fontSize: "13px",
}));
const Time = styled(Box)(() => ({
  position: "absolute",
  top: "2%",
  right: "5%",
  backgroundColor: "white",
  color: theme.palette.primary.dark,
  border: "1px solid white",
  borderRadius: "2px",
  display: "flex",
  fontSize: "13px",
  gap: "5px",
  alignItems: "center",
}));
const FeedbackButton = styled(Button)(() => ({
  textTransform: "none",
  color: theme.palette.primary.main,
  borderColor: theme.palette.primary.main,
  "&:hover": {
    borderColor: theme.palette.primary.main,
  },
}));
const FeedbackBox = styled(Box)(() => ({
  display: "flex",
  padding: "5px 10px 10px 10px",
  justifyContent: "space-between",
  alignItems: "center",
  gap: 1,
}));
const NoCourse = styled(Box)(() => ({
  width: "100%",
  [theme.breakpoints.up("md")]: {
    width: "40%",
  },
  margin: "150px auto",
  textAlign: "center",
  color: "gray",
  border: `2px solid ${theme.palette.secondary.main}`,
  padding: "30px",
}));
const ProgressBarBox = styled(Box)(() => ({
  alignItems: "center",
  justifyContent: "center",
  display: "flex",
}));

export const CardComponent = ({ elem, onClick = () => {} }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const [value, setValue] = React.useState(2);
  const [rating, setRating] = React.useState(0);
  const [average, setAvarage] = useState(0);
  const [open, setOpen] = React.useState(false);
  const { user, setUser } = useContext(UserContext);
  const [downloadMaterial, setDownloadMaterial] = React.useState(null);
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const handleClickOpen = () => {
    setOpen(true);
  };
  useEffect(() => {
    getCurrentUser();
  }, []);
  const getCurrentUser = async () => {
    try {
      let { data } = await get(urls.user.getCurrentUser);
      window.localStorage.setItem(
        "user-customer-webspruce",
        JSON.stringify(data.result)
      );
      setUser(data.result);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    const feedbacks = elem.courseId?.feedbacks || [];
    const totalRating =
      feedbacks.reduce((total, feed) => total + parseInt(feed?.rating), 0) || 0;

    let averageRating = totalRating / (feedbacks.length || 1);
    const getUserRating = feedbacks.filter((m) => m.givenBy == user._id);
    console.log(getUserRating, "getUserRating");
    setValue(averageRating);
    setAvarage(averageRating);
    setRating(getUserRating[0]?.rating);
  }, []);

  const handleClose = () => {
    setOpen(false);
  };
  const handleRating = async (id) => {
    try {
      const body = {
        ratings: rating,
      };
      const res = await post(`${urls.course.updateRating}/${id}`, body);
      console.log(res);
      handleClose();
    } catch (error) {
      console.log(error);
    }
  };

  const handleDownload = async (id, data) => {
    try {
      const res = await get(`${urls.course.downloadMaterial}/${id}`);
      const link = res.data.result;
      setDownloadMaterial(link);
      if (link) {
        window.open(link, "_blank");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleNavigate = (id) => {
    navigate(`/course/material/${id}`);
  };

  return (
    <>
      <Card sx={{ boxShadow: "10", height: "100%" }}>
        <CardActionArea onClick={() => handleNavigate(elem.courseId?._id)}>
          <CardMedia
            component="img"
            style={{ height: "180px" }}
            image={
              elem.courseId?.thumbnailImage
                ? elem.courseId?.thumbnailImage
                : courseImage
            }
            alt="green iguana"
          />
          <Box sx={{ width: "100%", marginTop: "15px" }}>
            <LinearProgressWithLabel value={elem.completed} />
          </Box>

          <CardContent sx={{ padding: "10px 10px" }}>
            <Time>
              <AccessTimeRoundedIcon sx={{ fontSize: "medium" }} />
              <Typography variant="body1" component="div" textAlign="center">
                {elem.courseId?.courseHours
                  ? elem.courseId?.courseHours
                  : "0 hrs"}
                hrs
              </Typography>
            </Time>

            <Design>{elem.courseId?.design}</Design>
            <Title variant="h6">{elem.courseId?.title}</Title>
            <Name>{elem.courseId?.name}</Name>
          </CardContent>
        </CardActionArea>
        <FeedbackBox>
          <FeedbackButton
            fullWidth
            variant="outlined"
            onClick={handleClickOpen}
            size="small">
            Give Feedback
          </FeedbackButton>
          {elem.courseId?.material === null ? null : (
            <Tooltip title="download">
              <FileDownloadIcon
                sx={{
                  cursor: "pointer",
                  color: theme.palette.primary.main,
                  "&:hover": {
                    opacity: 0.6,
                  },
                }}
                onClick={() =>
                  handleDownload(elem.courseId?._id, elem.courseId)
                }
              />
            </Tooltip>
          )}
        </FeedbackBox>
      </Card>

      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title">
        <Box sx={style}>
          <DialogTitle
            id="responsive-dialog-title"
            sx={{ textAlign: "center", fontWeight: "bold" }}>
            {"Feedback Rating"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}>
              <Rating
                size="large"
                name="simple-controlled"
                value={rating}
                onChange={(event, newValue) => {
                  setRating(newValue);
                }}
              />
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
            <Button
              onClick={() => handleRating(elem.courseId?._id)}
              autoFocus
              variant="contained">
              Submit
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
};

const Courses = () => {
  const navigate = useNavigate();
  const { user, setUser } = useContext(UserContext);
  const theme = useTheme();

  return (
    <>
      <Container maxWidth="xl" sx={{ marginTop: "20px" }}>
        <Grid container spacing={2}>
          {user.courses && user.courses?.length ? null : (
            <NoCourse>
              <Typography variant="h6">
                You don't have any COURSE in your account!
              </Typography>
              <Button
                onClick={() => navigate("/allcourse")}
                variant="contained"
                size="small"
                sx={{
                  marginTop: "30px",
                }}>
                Explore
              </Button>
            </NoCourse>
          )}

          {user.courses
            ?.sort((x, y) => x.courseId?.title.localeCompare(y.courseId?.title))
            .map((elem, index) => (
              <Grid item xs={12} md={4} sm={6} lg={3} key={index}>
                <CardComponent key={index} elem={elem} />
              </Grid>
            ))}
        </Grid>
      </Container>
    </>
  );
};
export default Courses;
