import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import {
  Box,
  CardActionArea,
  Container,
  FormControl,
  Grid,
  InputLabel,
  OutlinedInput,
  MenuItem,
  Select,
  TextField,
  styled,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { blueGrey } from "@mui/material/colors";
import AccessTimeRoundedIcon from "@mui/icons-material/AccessTimeRounded";
import { Search } from "@mui/icons-material";
import Rating from "@mui/material/Rating";
import { get } from "../../api/apiMethods";
import { urls } from "../../api/urlsContants";
import image from "../../assets/Images/courseimg.png";
import { useNavigate, useParams } from "react-router-dom";
import dayjs from "dayjs";
import { useTheme } from "@mui/material/styles";
import SortIcon from "@mui/icons-material/Sort";

import Lottie from "react-lottie";
import animationData from "../../components/lotties/NoDatafound.json";
import FilterListIcon from "@mui/icons-material/FilterList";

// const Design = Typography;
// const Title = Typography;
// const Content = Typography;
const Design = styled(Typography)(() => ({
  color: "",
  fontSize: "12px",
}));

const Star = styled(Rating)(() => ({
  fontSize: "18px",
}));

const Title = styled(Typography)(({ theme }) => ({
  textTransform: "uppercase",
  fontSize: "15px",
  fontWeight: "bold",
  marginTop: "2%",
  color: theme.palette.primary.main,
}));

const Content = styled(Typography)(({ theme }) => ({
  fontSize: "13px",
  // color: theme.palette.secondary.main,
  color: "black",
  display: "-webkit-box",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: 1,
  overflow: "hidden",
  textOverflow: "ellipsis",
  height: 18,
}));
const Time = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "10px",
  right: "0",
  backgroundColor: theme.palette.primary.main,
  borderTopLeftRadius: "5px",
  borderBottomLeftRadius: "5px",
  width: "130px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "10px 0",
  height: "40px",
  boxShadow: "0px 8px 6px -6px black",
  clipPath: "polygon(15% 50%, 0% 0%, 100% 0%,100% 100%,  0% 100%)",
}));

const Label = styled("label")(() => ({
  fontWeight: "bold",
  fontSize: "14px",
}));
const BoxName = Box;
// const Star = Rating;
// const Time = Box;

export const CardComponent = () => {
  const { id } = useParams();
  const [editCourse, setEditCourse] = useState(null);
  const [courses, setCourses] = useState([]);
  const [courseDetail, setCourseDetail] = useState([]);
  const [filteredCourses, setFilteredCourses] = useState([]);
  const [menuList, setMenuList] = useState([]);
  const [search, setSearch] = useState("");
  const [coursesFilter, setCoursesFilter] = useState([]);
  const [selectedMenu, setSelectedMenu] = useState("All");
  const [selectedSort, setSelectedSort] = useState("courseName");
  const theme = useTheme();
  const [averageRatings, setAverageRatings] = useState({});

  const handleUpdateSelectedSort = (e) => setSelectedSort(e.target.value);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  useEffect(() => {
    if (selectedSort === "courseName") {
      setFilteredCourses(
        courseDetail.sort((a, b) => a.title.localeCompare(b.title))
      );
    }
    if (selectedSort === "rating") {
      const sortedCourses = [...courseDetail].sort((a, b) => {
        const ratingA = averageRatings[a._id] || 0;
        const ratingB = averageRatings[b._id] || 0;
        return ratingB - ratingA;
      });
      setFilteredCourses(sortedCourses);
    }
    if (selectedSort === "trending") {
      setFilteredCourses(
        courseDetail.sort((a, b) => {
          return b.isTrending - a.isTrending;
        })
      );
    }
  }, [selectedSort, courseDetail, averageRatings]);

  const navigate = useNavigate();

  const handleChange = (event) => {
    setSelectedMenu(event.target.value);
    // setMenuList(event.target.value);
  };

  useEffect(() => {
    const ratings = {};
    courses.forEach((course) => {
      if (course.feedbacks && Array.isArray(course.feedbacks)) {
        const totalRatings = course.feedbacks.reduce(
          (sum, feedback) => sum + Number(feedback.rating),
          0
        );
        const averageRating = totalRatings / (course.feedbacks.length || 1);

        ratings[course._id] = averageRating;
      } else {
        ratings[course._id] = 0;
      }
    });

    setAverageRatings(ratings);
  }, [courses]);

  useEffect(() => {
    const getCourse = async () => {
      try {
        const response = await get(urls.course.getList);
        const coursesData = response.data.result.data;
        // console.log(coursesData, "==========uuuuuuuuu");

        const filteredCourses = coursesData.filter(
          (course) => course.menu?.activated
        );

        setCourseDetail(filteredCourses);
        setFilteredCourses(filteredCourses);

        setCourses(coursesData);

        const getMeuList = filteredCourses.map((c) => c.menu.name);
        const uniqueMenuList = [...new Set(getMeuList)];
        setMenuList(["All", ...uniqueMenuList]);
      } catch (error) {
        console.error(error);
      }
    };
    getCourse();
  }, []);

  useEffect(() => {
    if (selectedMenu) {
      if (selectedMenu === "All") {
        setFilteredCourses(courseDetail);
      } else {
        setFilteredCourses(
          courseDetail.filter((c) => c.menu.name === selectedMenu)
        );
      }
    }
  }, [selectedMenu]);

  useEffect(() => {
    if (search.trim() !== "") {
      let filteredResult = filteredCourses.filter((ele) =>
        ele.title.toLowerCase().includes(search.toLowerCase().trim())
      );
      setCoursesFilter(filteredResult);
    } else {
      setCoursesFilter([]);
    }
  }, [search, courses, filteredCourses]);

  const handleNavigate = (id, course) => {
    const selectedCourse = courses.find((course) => course._id === id);
    if (selectedCourse) {
      navigate(`/course/content/${id}`);
    }
  };

  const currentDate = new Date();

  const earlyBirdEndDate = new Date();

  let getCurrentPrice;

  if (earlyBirdEndDate > currentDate) {
    getCurrentPrice =
      courses && courses.selfPacedInfo
        ? courses.selfPacedInfo.earlyBirdPrice
        : "";
  } else {
    getCurrentPrice =
      courses && courses.selfPacedInfo
        ? courses.selfPacedInfo.standardPrice
        : "";
  }

  // console.log(courses, "---getCurrentPrice");

  return (
    <>
      <Container sx={{ mt: "15px" }} maxWidth="xl">
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: { xs: "column", sm: "row" },
          }}
        >
          <Grid
            container
            rowSpacing={2}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              minWidth: "227px",
              flex: 1,
            }}
          >
            <Grid item xs={12} sm={3} md={3} lg={2.5} mt={1}>
              <TextField
                size="small"
                placeholder="Search"
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton edge="end" type="submit">
                        <Search />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                sx={{
                  "& .MuiInputBase-root": {
                    borderRadius: "20px",
                    height: "35px",
                    minWidth: "227px",
                    boxShadow: 0.5,
                  },
                }}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </Grid>
          </Grid>
          <Grid
            container
            rowSpacing={2}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              gap: "5px",
            }}
          >
            <Grid
              item
              xs={12}
              sm={3}
              md={3}
              lg={2.5}
              // sx={{ flex: 1, marginLeft: { xs: 0, sm: 0.1 } }}
            >
              <FormControl fullWidth>
                <Box sx={{ display: "flex" }}>
                  {" "}
                  <FilterListIcon fontSize="small" />
                  <Label id="demo-simple-select-label">Filter Category</Label>
                </Box>
                <Select
                  labelId="demo-multiple-name-label"
                  id="demo-multiple-name"
                  value={selectedMenu}
                  onChange={handleChange}
                  size="small"
                  input={
                    <OutlinedInput
                      // label="Category"
                      sx={{
                        borderRadius: "20px",
                        textAlign: "center",
                        height: "35px",
                      }}
                    />
                  }
                >
                  {menuList?.map((menuList) => (
                    <MenuItem key={menuList} value={menuList}>
                      {menuList}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6} md={3} lg={2.5}>
              <FormControl fullWidth>
                <Box sx={{ display: "flex", paddingLeft: "10px" }}>
                  {" "}
                  <SortIcon fontSize="small" />
                  <Label id="demo-simple-select-label">Sort By</Label>
                </Box>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedSort}
                  onChange={handleUpdateSelectedSort}
                  size="small"
                  input={
                    <OutlinedInput
                      sx={{
                        borderRadius: "20px",
                        textAlign: "center",
                        height: "35px",
                      }}
                    />
                  }
                >
                  <MenuItem value={"courseName"}>Course Name</MenuItem>
                  <MenuItem value={"rating"}>Rating</MenuItem>
                  <MenuItem value={"trending"}>Trending</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Box>
        <Grid container sx={{ display: "flex" }} spacing={2} mt={2}>
          {(search.trim().length ? coursesFilter : filteredCourses).map(
            (elem, i) => (
              <Grid key={i} item xs={12} sm={6} md={4} lg={3}>
                {selectedSort === "trending" && !elem.isTrending ? null : (
                  <Card
                    onClick={() => handleNavigate(elem._id, elem)}
                    sx={{
                      boxShadow: "5",
                      height: "100%",
                      // position: "relative",
                    }}
                  >
                    <CardActionArea>
                      <CardMedia
                        height={180}
                        component="img"
                        image={
                          elem.thumbnailImage ? elem.thumbnailImage : image
                        }
                        alt="Course Thumbnail"
                      />
                      <CardContent sx={{ padding: "0px 15px 10px" }}>
                        <Design>{elem.design}</Design>
                        <Title
                          gutterBottom
                          sx={{
                            display: "-webkit-box",
                            margin: "5px 0",
                            WebkitLineClamp: 1,
                            overflow: "hidden",
                            WebkitBoxOrient: "vertical",
                          }}
                        >
                          {elem.title}
                        </Title>
                        <Content
                        // variant="body2"

                        // style={{
                        //   display: "-webkit-box",
                        //   overflow: "hidden",
                        //   WebkitBoxOrient: "vertical",
                        //   WebkitLineClamp: 1,
                        //   textOverflow: "ellipsis",
                        //   whiteSpace: "normal",
                        //   height: 40,
                        // }}
                        >
                          <div>{elem.description}</div>
                        </Content>
                        <Time>
                          <Typography
                            sx={{
                              fontWeight: "bold",
                              fontSize: "16px",
                              // marginTop: "5px",
                              // color: elem.isPaid != false ? "#ffff" : "green",
                              color: "#fff",
                            }}
                          >
                            {elem.isPaid === true
                              ? `₹${
                                  dayjs(Date.now()).diff(
                                    new Date(
                                      elem?.selfPacedInfo?.earlyBirdEndDate
                                    ),
                                    "minutes"
                                  ) < 0
                                    ? elem?.selfPacedInfo?.earlyBirdPrice
                                    : elem?.selfPacedInfo?.standardPrice
                                }`
                              : "Free"}
                            {elem.isPaid === true ? (
                              dayjs(Date.now()).diff(
                                new Date(elem?.selfPacedInfo?.earlyBirdEndDate),
                                "minutes"
                              ) < 0 ? (
                                <s
                                  style={{
                                    color: "#fff",
                                    marginLeft: "10px",
                                    fontSize: "14px",
                                  }}
                                >
                                  {elem.selfPacedInfo.standardPrice}
                                </s>
                              ) : null
                            ) : (
                              ""
                            )}
                          </Typography>
                        </Time>
                        <Box
                          sx={{
                            display: "flex",
                            marginTop: "10px",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <Box sx={{ display: "flex" }}>
                            <Typography
                              sx={{
                                color: theme.palette.primary.main,
                                fontSize: "13px",
                              }}
                            >
                              {averageRatings[elem._id]}
                            </Typography>
                            {averageRatings[elem._id] === 0 ||
                            averageRatings[elem._id] ? (
                              <Star
                                id={elem._id}
                                name="simple-controlled"
                                value={averageRatings[elem._id]}
                                // fontSize="20px"
                                readOnly
                                // onChange={(event, newValue) => {
                                //   setValue(newValue);
                                // }}
                              />
                            ) : null}

                            <Typography sx={{ fontSize: "13px" }}>
                              (
                              {elem && elem.feedbacks
                                ? elem.feedbacks.length
                                : "0"}
                              )
                            </Typography>
                          </Box>

                          <Typography
                            sx={{
                              display: "flex",
                              fontWeight: "bold",
                              fontSize: "13px",
                              // marginLeft: "85px",
                              alignItems: "center",
                              color: theme.palette.primary.main,
                            }}
                          >
                            <AccessTimeRoundedIcon
                              sx={{
                                fontSize: "13px",
                                marginRight: "5px",
                              }}
                            />
                            {elem.courseHours}hrs
                          </Typography>
                          {i >= filteredCourses.length - 10 && (
                            <Box
                              sx={{
                                position: "absolute",
                                top: "10px",
                                left: "10px",
                                backgroundColor: "#00FF00",
                                color: "#000",
                                borderRadius: "5px",
                                padding: "5px",
                                fontSize: "12px",
                                fontWeight: "bold",
                              }}
                            >
                              New
                            </Box>
                          )}
                        </Box>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                )}
              </Grid>
            )
          )}

          {(search.trim().length ? coursesFilter : filteredCourses).length ===
            0 && (
            <Grid item xs={12}>
              <Box
                p={1}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  width: "400px",
                  height: "350px",

                  backgroundColor: "#fff",
                  margin: "auto",
                }}
              >
                <Lottie
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  options={defaultOptions}
                />
              </Box>
            </Grid>
          )}
        </Grid>
        <Box sx={{ mb: 2 }} />
      </Container>
    </>
  );
};

const AllCourses = () => {
  return (
    <Container maxWidth="xl">
      <CardComponent />
    </Container>
  );
};

export default AllCourses;
