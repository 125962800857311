export const urls = {
  menu: {
    createMenu: "/menu/create",
    editMenu: "/menu/updateName",
    getList: "/menu/list",
    updateStatus: "/menu/changeStatus",
  },
  // course: {
  //   createCourse: "/course/create",
  //   updateCourse: "/course/update",
  //   getCourse: "/course/list",
  //   getCourseName: "/course/getCoursesNames",
  // },
  user: {
    getOneUser: "/account/getOneUser",
    getAllUser: "/account/list",
    getSubscriberList: "/account/getSubscriberList",
    getOneCustomerUser: "/account/getOneCustomerUser",
    getMyCourses: "/account/getMyCourses",
    createAccount: "/account/create",
    accountLogin: "/account/login",
    updateInfo: "/account/updateInfo",
    resetPassword: "/account/resetPassword",
    getMyInvoices: "/account/getMyInvoices",
    downloadInvoice: "/account/downloadInvoice",
    generateCertificate: "/account/generateCertificate",
    resetPasswordLink: "/account/resetPasswordLink",
    resetForgotPasswordInLink: "/account/resetForgotPasswordInLink",
    updateCourseStatus: "/account/updateCourseStatus",
    getCurrentUser: "/account/getCurrentUser",
  },
  course: {
    createCourse: "/course/create",
    editCourse: "/course/update",
    getList: "/course/list",
    getOnevalue: "/course/getOnevalue",
    updateSelfPaced: "/course/updateSelfPacedInfo",
    updateInstructorLedInfo: "/course/updateInstructorLedInfo",
    addInstructorLedSchedule: "/course/addInstructorLedSchedule",
    updateInstructorLedSchedule: "/course/updateInstructorLedSchedule",
    updateClassRoomInfo: "/course/updateClassRoomInfo",
    addClassRoomSchedule: "/course/addClassRoomSchedule",
    updateClassRoomSchedule: "/course/updateClassRoomSchedule",
    changeStatus: "/course/changeStatus",
    changeClassStatus: "/course/changeClassStatus",
    deleteCourse: "/course/delete",
    downloadMaterial: "/course/downloadMaterial",
    updateRating: "/course/updateRating",
  },
  courseContent: {
    createCourseContent: "/content/create",
    getCourseContent: "/content/details",
    getContents: "/account/getCourseContent/",
  },

  enquiry: {
    getEnquiry: "/enquiry/list",
    createForm: "/enquiry/create",
  },
  payment: {
    getAllPaidPayments: "/payment/getAllPaidPayments",
  },
  auth: {
    genOtp: "/account/generateResetPasswordOtp",
    resetPassword: "/account/resetForgotPassword",
    sendEmailConfirmationCode: "/account/sendEmailConfirmationCode",
    verifyEmailCode: "/account/verifyEmailCode",
  },
  live: {
    getAllMeetings: "/live/list",
    joinMeeting: "/live/join",
  },
};
