const urls = {
  course: {
    getCourse: "course/list",
    createCourse: "course/create",
    valueCourse: "course/getOnevalue",
    getOnevalue: "course/getOnevalue",
  },
  enquiry: {
    createForm: "/enquiry/create",
  },
  courseContent: {
    getCourseContent: "/content/details",
  },
  menu: {
    getMenuList: "/menu/list",
  },
  auth: {
    genOtp: "/account/generateResetPasswordOtp",
    resetPassword: "/account/resetForgotPassword",
  },
  payment: {
    order: "payment/order",
  },
  coupan: {
    list: "/coupan/list",
    checkCoupan: "/coupan/checkCoupan",
  },
  user: {
    getCurrentUser: "/account/getCurrentUser",
  },
};

export default urls;
