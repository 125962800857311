import {
  Box,
  Button,
  Container,
  Grid,
  Tab,
  Tabs,
  FormLabel,
  Typography,
  styled,
  Card,
  Divider,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import logo from "../../assets/Images/course.png";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import FormInput from "../FormInput";
import { useFormik } from "formik";
import * as Yup from "yup";
import { post, get } from "../../api/apiMethods";
import { urls } from "../../api/urlsContants";
import { LoadingButton } from "@mui/lab";
import { theme } from "../../theme";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}

const Form = styled(Box)(() => ({
  padding: 20,
  borderRadius: "5px",
  marginTop: 20,
  background: "white",
}));

const PmpHeading = styled(Typography)(({ theme }) => ({
  fontFamily: "Quicksand",
  fontSize: "30px",
  fontWeight: 600,
  marginLeft: 80,
  [theme.breakpoints.down("sm")]: {
    marginLeft: 0,
    textAlign: "center",
    fontSize: "20px",
  },
}));

const Details = styled(Box)(({ theme }) => ({
  display: "flex",
  marginLeft: 65,
  fontFamily: "Inter",
  marginTop: 2,
  [theme.breakpoints.down("sm")]: {
    marginLeft: 30,
  },
}));

const PathNames = styled(Typography)(({ theme }) => ({
  marginLeft: 80,
  color: "#9e9e9e",
  fontFamily: "Inter",
  [theme.breakpoints.down("sm")]: {
    marginLeft: 0,
    textAlign: "center",
    fontSize: "20px",
  },
}));

const FormHeading = styled(Grid)(({ theme }) => ({
  color: "black",
  fontWeight: "bold",
  textAlign: "center",
}));

const Para = styled("div")(({ theme }) => ({
  color: "#555",
  lineHeight: "24px",
  margin: "15px 0",
  fontSize: "15px",
  textAlign: "justify",
  animationDelay: "0.5s",
}));

const CourseContent = () => {
  const { id } = useParams();
  const [tabValue, setTabValue] = useState(0);
  const [courses, setCourse] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    const getCourse = async () => {
      try {
        const response = await get(`${urls.course.getOnevalue}/${id}`);
        setCourse(response.data.result.data);
        // console.log(response.data.result.data, "coursssssssssssssss");
      } catch (error) {
        console.log(error);
      }
    };
    getCourse();
  }, [id]);

  // console.log(courses,"coursesvvvvvvvvvvvvvv")

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      contactNumber: "",
      message: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required().label("Name").max(150),
      email: Yup.string().email().required().label("Email").max(150),
      contactNumber: Yup.string().required().label("Contact Number").min(10),
      message: Yup.string().required().label("Message"),
    }),
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const body = {
          subject: "For More Details",
          customerDetails: {
            name: values.name,
            email: values.email,
            contactNumber: values.contactNumber,
          },
          enquiryDetails: {
            courseId: id,
            programmeName: "For More Details",
            programmeDetails: {},
          },
          message: values.message,
        };

        const data = await post(`${urls.enquiry.createForm}`, body);
        formik.resetForm();
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }

      // console.log(values);
    },
    validateOnBlur: false,
  });

  const handleNavigate = () => {
    navigate(`/studentCheckout/${courses._id}`);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "0px 40px",
          margin: "20px",
        }}>
        <Grid container>
          <Grid item xs={12} sm={6} md={6}>
            <Typography
              variant="h5"
              sx={{
                color: theme.palette.secondary.main,
                fontWeight: 600,
              }}>
              {courses?.title}
            </Typography>
            <Box>
              {/* <Para> */}
              <Para
                dangerouslySetInnerHTML={{
                  __html: courses.highlights,
                }}
              />
              {/* </Para> */}
            </Box>
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            sx={{
              display: "flex",
              justifyContent: {
                xs: "center",
                sm: "center",
                md: "flex-end",
                lg: "flex-end",
              },
              // alignItems: "center",
            }}>
            <img
              style={{ height: 150, borderRadius: "5px" }}
              src={courses.thumbnailImage ? courses.thumbnailImage : logo}
              alt=""
            />
          </Grid>
        </Grid>
      </Box>
      <Box bgcolor="whitesmoke" sx={{ minHeight: 350 }}>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          sx={{ background: "#fff" }}
          scrollButtons
          allowScrollButtonsMobile
          textColor="inherit"
          indicatorColor="secondary"
          aria-label="basic tabs example"
          variant="scrollable">
          <Tab label="Overview" {...a11yProps(0)} />
          <Tab label="Key Features" {...a11yProps(1)} />
          <Tab label="Course outline" {...a11yProps(2)} />
          <Tab label="Benefits" {...a11yProps(3)} />
          <Tab label="Who should attend" {...a11yProps(4)} />
        </Tabs>

        <Box sx={{ padding: "0px 40px" }}>
          <TabPanel value={tabValue} index={0}>
            <Box>
              {/* <Para> */}
              <Para
                dangerouslySetInnerHTML={{
                  __html: courses?.courseDetails?.overview,
                }}
              />
              {/* </Para> */}
            </Box>
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <Box>
              {/* <Para> */}
              <Para
                dangerouslySetInnerHTML={{
                  __html: courses?.courseDetails?.keyFeatures,
                }}
              />
              {/* </Para> */}
            </Box>
          </TabPanel>
          <TabPanel value={tabValue} index={2}>
            <Box>
              {/* <Para> */}
              <Para
                dangerouslySetInnerHTML={{
                  __html: courses?.courseDetails?.courseOutline,
                }}
              />
              {/* </Para> */}
            </Box>
          </TabPanel>
          <TabPanel value={tabValue} index={3}>
            <Box>
              {/* <Para> */}
              <Para
                dangerouslySetInnerHTML={{
                  __html: courses?.courseDetails?.benefits,
                }}
              />
              {/* </Para> */}
            </Box>
          </TabPanel>

          <TabPanel value={tabValue} index={4}>
            <Box>
              {/* <Para> */}
              <Para
                dangerouslySetInnerHTML={{
                  __html: courses?.courseDetails?.whoIsItFor,
                }}
              />
              {/* </Para> */}
            </Box>
          </TabPanel>
        </Box>
      </Box>

      <Grid container my={2} sx={{ padding: "0px 40px", margin: "20px" }}>
        <Grid item xs={12} md={6} lg={6}>
          <Typography
            sx={{
              color: "black",
              fontWeight: "bold",
              fontSize: "22px",
              marginBottom: "5px",
              // textAlign: "center",
            }}>
            For More Details
          </Typography>
          <Form
            component={"form"}
            onSubmit={formik.handleSubmit}
            boxShadow={3}
            sx={{
              width: { sm: "60%", md: "60%", lg: "50%" },
              // margin: "auto 0",
              padding: "20px",
            }}>
            {/* <Grid container spacing={1}> */}
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <FormLabel>Name</FormLabel>
              <FormInput formik={formik} name="name" type="text" />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <FormLabel>Email</FormLabel>
              <FormInput formik={formik} name="email" type="email" />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <FormLabel>Contact No</FormLabel>
              <FormInput formik={formik} name="contactNumber" />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <FormLabel>Your Message</FormLabel>
              <FormInput
                formik={formik}
                name="message"
                type="text"
                multiline={true}
                rows={3}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} mt={1}>
              <LoadingButton
                disableElevation
                type="submit"
                variant="contained"
                size="small"
                loading={loading}
                // sx={{
                //   background: "#747DE8",
                //   "&:hover": {
                //     background: "#747DE8",
                //   },
                // }}
              >
                Send
              </LoadingButton>
            </Grid>
            {/* </Grid> */}
          </Form>
        </Grid>

        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            gap: "10px",
          }}>
          <Typography sx={{ fontWeight: "bold", textAlign: "start" }}>
            {courses?.title}
          </Typography>
          <Button
            variant="contained"
            // sx={{
            //   color: "white",
            //   backgroundColor: "#757de8",
            //   fontWeight: "bold",
            //   borderRadius: "20px",
            //   "&:hover": {
            //     color: "white",
            //     backgroundColor: "#757de8",
            //   },
            // }}
            onClick={handleNavigate}>
            Enroll Now
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default CourseContent;
