import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Button,
  Typography,
  Grid,
  styled,
  FormGroup,
  FormControlLabel,
  Checkbox,
  IconButton,
  InputAdornment,
} from "@mui/material";

import { GoogleLogin } from "@react-oauth/google";
import { LoadingButton } from "@mui/lab";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";

import { post } from "../../../api/apiMethods";
import { urls } from "../../../api/urlsContants";
import NavbarContext from "../../../context/NavbarContext";
import FormInput from "../../form/FormInput";
import Laptop from "../../../assets/Images/laptop.png";
import towPiece from "../../../assets/Images/towpiece.png";
import UserContext from "../../../context/UserContext";
import logo from "../../../assets/Images/websaprucee.png";
import { RippelVideoButton } from "../../RippelVideoButton";
import { theme } from "../../../theme";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const SigninBox = styled(Box)(() => ({
  background: `${theme.palette.secondary.dark}`,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  borderRadius: "50px",
}));

const SigninButton = styled(Button)(() => ({
  color: "#fff",
  borderRadius: "20px",
  margin: "5px",
  padding: "10px 50px",
  fontSize: "15px",
  backgroundColor: `${theme.palette.primary.dark}`,
}));

const SignupButton = styled(Button)(() => ({
  textDecoration: "none",
  color: "#fff",
  marginRight: "10px",
}));

const RippelBox = styled(Box)(() => ({
  margin: "auto",
  transform: "rotate(-20deg)",
  textDecoration: "none",
  color: "#fff",
  marginRight: "10px",
}));

const Main = styled(Box)(() => ({
  height: "100vh",
  width: "100%",
  display: "flex",
  overflow: "hidden",
  justifyContent: "space-between",
}));

const LeftBox = styled(Box)(() => ({
  display: "flex",
  height: "100%",
  backgroundImage: `url(${towPiece})`,
  backgroundRepeat: "no-repeat",
  backgroundPositionY: "100%",
}));

const RightBox = styled(Box)(() => ({
  position: "relative",
  backgroundImage: `url(${Laptop})`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  justifyContent: "end",
  backgroundPositionY: "center",
}));

const inputForm = {
  width: { xs: "100%", sm: "80%", md: "80%", lg: "60%" },
  margin: "20px auto",
  padding: "0 20px",
};

export const Login = () => {
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const { setShowNavBar } = useContext(NavbarContext);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    setShowNavBar(false);
    return () => setShowNavBar(true);
  }, []);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email().required().label("Email").max(150),
      password: Yup.string().required().label("Password").max(32),
    }),
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const body = {
          email: values.email,
          password: values.password,
          userType: "customer",
          rememberMe,
        };
        const res = await post(urls.user.accountLogin, body);
        if (res.status > 199 && res.status < 299) {
          window.localStorage.setItem(
            "user-customer-webspruce",
            JSON.stringify(res.data.result.user)
          );

          window.localStorage.setItem(
            "access_token",
            res.data.result.access_token
          );

          setLoading(false);
          navigate("/allcourse");
        }
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    },
    validateOnBlur: false,
  });

  const loginWithGoogle = async (credential) => {
    setLoading(true);
    try {
      const body = {
        userType: "customer",
        credential,
      };

      const res = await post(urls.user.accountLogin, body);

      if (res.status > 199 && res.status < 299) {
        toast.success(res.data.message.split("_").join(" "));
        window.localStorage.setItem(
          "user-customer-webspruce",
          JSON.stringify(res.data.result.user)
        );

        window.localStorage.setItem(
          "access_token",
          res.data.result.access_token
        );

        setLoading(false);
        navigate("/allcourse");
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };
  return (
    <>
      <Main>
        <LeftBox
          sx={{
            width: { xs: "100%", sm: "100%", md: "50%", lg: "40%" },
          }}>
          <Grid container sx={inputForm}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <img
                src={logo}
                height={100}
                width={300}
                style={{ objectFit: "contain" }}
              />
              <SigninBox my={3}>
                <SigninButton variant="contained">Sign in</SigninButton>
                <Link to="/signup">
                  <SignupButton>Sign up</SignupButton>
                </Link>
              </SigninBox>
              <Box component="form" onSubmit={formik.handleSubmit}>
                <Grid item xs={12} sm={12} md={12} lg={12} my={1}>
                  <FormInput
                    formik={formik}
                    name="email"
                    type="email"
                    label="Email"
                    size="small"
                    variant="outlined"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} my={1}>
                  {/* <FormInput
                    formik={formik}
                    name="password"
                    type="password"
                    label="Enter Password"
                    size="small"
                    variant="outlined"
                  /> */}
                  <FormInput
                    formik={formik}
                    name="password"
                    type={showPassword ? "text" : "password"}
                    label="Enter Password"
                    size="small"
                    inputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {showPassword ? (
                            <IconButton
                              onClick={() => setShowPassword(!showPassword)}>
                              <VisibilityOff fontSize="small" />
                            </IconButton>
                          ) : (
                            <IconButton
                              onClick={() => setShowPassword(!showPassword)}>
                              <Visibility fontSize="small" />
                            </IconButton>
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12} my={1}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          sx={{
                            color: theme.palette.primary.dark,
                            "&.Mui-checked": {
                              color: theme.palette.primary.dark,
                            },
                          }}
                        />
                      }
                      type="checkbox"
                      onChange={(e) => {
                        setRememberMe(e.target.checked);
                      }}
                      label="Remember Me"
                    />
                  </FormGroup>
                </Grid>
                <Typography
                  variant="body"
                  sx={{ marginTop: "10px", fontSize: 14 }}>
                  By continuing, you agree to{" "}
                  <Link
                    to="/privacy-policy"
                    style={{
                      textDecoration: "none",
                      color: `${theme.palette.primary.dark}`,
                      fontWeight: "bold",
                    }}>
                    {" "}
                    Privacy Policy
                  </Link>{" "}
                  ,{" "}
                  <Link
                    to="/refund-policy"
                    style={{
                      textDecoration: "none",
                      color: `${theme.palette.primary.dark}`,
                      fontWeight: "bold",
                    }}>
                    Refund Policy
                  </Link>
                  ,and
                  <Link
                    to="/terms-service"
                    style={{
                      textDecoration: "none",
                      color: `${theme.palette.primary.dark}`,
                      fontWeight: "bold",
                    }}>
                    {" "}
                    Terms of Service.
                  </Link>
                </Typography>
                <Grid item xs={12} sm={12} md={12} lg={12} my={1}>
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    loading={loading}
                    fullWidth>
                    Sign In
                  </LoadingButton>
                </Grid>
                <Link to="/forgot-password" style={{ textDecoration: "none" }}>
                  <Typography
                    variant="body"
                    textAlign="center"
                    sx={{
                      cursor: "pointer",
                      color: `${theme.palette.primary.dark}`,
                      "&:hover": {
                        opacity: 0.8,
                      },
                    }}>
                    Forgot password
                  </Typography>
                </Link>
              </Box>
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
              pb={7}>
              <Box my={1}>OR </Box>

              <GoogleLogin
                size="large"
                shape="circle"
                onSuccess={(credentialResponse) => {
                  loginWithGoogle(credentialResponse.credential);
                }}
                onError={() => {
                  console.log("Login Failed");
                }}
              />
            </Grid>
          </Grid>
        </LeftBox>
        <RightBox
          sx={{
            width: { xs: "0", sm: "65%", md: "65%", lg: "60%" },
            backgroundPositionX: { md: "center", lg: "35px" },
            display: { xs: "none", sm: "none", md: "block", lg: "block" },
          }}>
          <RippelBox
            sx={{
              position: "absolute",
              top: { md: "42%", lg: "42%" },
              left: { md: "36%", lg: "42%" },
            }}>
            <RippelVideoButton />
          </RippelBox>
        </RightBox>
      </Main>
    </>
  );
};
