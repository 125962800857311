// import {
//   Box,
//   Button,
//   Grid,
//   InputLabel,
//   Link,
//   TextField,
//   Typography,
// } from "@mui/material";
// import React, { useState } from "react";
// import Radio from "@mui/material/Radio";
// import RadioGroup from "@mui/material/RadioGroup";
// import FormControlLabel from "@mui/material/FormControlLabel";
// import FormControl from "@mui/material/FormControl";
// import FormLabel from "@mui/material/FormLabel";
// import styled from "@emotion/styled";

// const label = { inputProps: { "aria-label": "Checkbox demo" } };
// const style = {
//   width: 500,
//   bgcolor: "background.paper",
//   border: "2px solid white",
//   boxShadow: 24,
//   p: 4,
// };

// const InputLabels = styled(InputLabel)(() => ({
//   fontSize: "12px",
//   fontWeight: 580,
//   color: "#616161",
// }));

// const RadioButtons = styled(Radio)(() => ({
//   marginTop: "-10px",
// }));
// const Boxes = styled(Box)(() => ({
//   display: "flex",
// }));

// const AttendQuizs = () => {
//   const [quiz, setQuiz] = useState({
//     option1: "",
//     option2: "",
//     option3: "",
//     option4: "",
//   });

//   const [value, setValue] = React.useState("");
//   const [formCount, setFormCount] = useState(1);

//   let { option1, option2, option3, option4 } = quiz;

//   const handleAddQuiz = () => {
//     setFormCount(formCount + 1);
//   };
//   const handleCancel = () => {
//     setFormCount(formCount - 1);
//   };
//   //radio selection
//   const handleChange = (event) => {
//     setValue(event.target.value);
//     let name = event.target.name;
//     let value = event.target.value;
//     setQuiz({
//       ...quiz,
//       [name]: value,
//     });
//   };

//   const handleSubmit = (event) => {
//     event.preventDefault();
//     //   quizName.setQuizData([...quizName.quizData, quiz]);
//     setQuiz("");
//   };
//   let a = 1;

//   const renderForms = () => {
//     const forms = [];
//     for (let i = 0; i < formCount; i++) {
//       forms.push(
//         <Grid container spacing={2} key={i} sx={{ marginTop: 2 }}>
//           <Grid item xs={12} md={6}>
//             <Boxes>
//               <InputLabels>Option1</InputLabels>
//               <RadioButtons
//                 {...label}
//                 size="small"
//                 color="default"
//                 checked={value === "option1"}
//                 onChange={handleChange}
//                 value="option1"
//                 type="radio"
//               />
//             </Boxes>
//             <TextField
//               fullWidth
//               size="small"
//               name="option1"
//               value={option1}
//               onChange={handleChange}
//             />
//           </Grid>
//           <Grid item xs={12} md={6}>
//             <Boxes>
//               <InputLabels>Option2</InputLabels>
//               <RadioButtons
//                 {...label}
//                 size="small"
//                 color="default"
//                 checked={value === "option2"}
//                 onChange={handleChange}
//                 value="option2"
//                 type="radio"
//               />
//             </Boxes>
//             <TextField
//               fullWidth
//               size="small"
//               name="option2"
//               value={option2}
//               onChange={handleChange}
//             />
//           </Grid>
//           <Grid item xs={12} md={6}>
//             <Boxes>
//               <InputLabels>Option3</InputLabels>
//               <RadioButtons
//                 {...label}
//                 size="small"
//                 color="default"
//                 checked={value === "option3"}
//                 onChange={handleChange}
//                 value="option3"
//                 type="radio"
//               />
//             </Boxes>
//             <TextField
//               fullWidth
//               size="small"
//               name="option3"
//               value={option3}
//               onChange={handleChange}
//             />
//           </Grid>
//           <Grid item xs={12} md={6}>
//             <Boxes>
//               <InputLabels>Option4</InputLabels>
//               <RadioButtons
//                 {...label}
//                 size="small"
//                 color="default"
//                 checked={value === "option4"}
//                 onChange={handleChange}
//                 value="option4"
//                 type="radio"
//               />
//             </Boxes>
//             <TextField
//               fullWidth
//               size="small"
//               name="option4"
//               value={option4}
//               onChange={handleChange}
//             />
//           </Grid>
//         </Grid>
//       );
//     }

//     return forms;
//   };

//   return (
//     <Box>
//       <Typography sx={{ fontSize: "large" }}>
//         Assume Site A adds Rel="nofollow" on a link to site B.what effect will
//         this have?
//       </Typography>

//       <Typography sx={{ fontWeight: "bold", fontSize: "20px" }}>
//         SELECT THE CORRECT ANSWER
//       </Typography>

//       <form onSubmit={handleSubmit}>
//         <Box
//           sx={{
//             display: "flex",
//             justifyContent: "center",
//             alignItems: "center",
//           }}>
//           {renderForms()}
//         </Box>
//         <Grid container spacing={2}>
//           <Grid item xs={12} md={12} sm={12}>
//             <Button variant="contained">Next</Button>
//           </Grid>
//         </Grid>
//       </form>
//     </Box>
//   );
// };

// export default AttendQuizs;

// import React, { useState } from 'react';
// import { FormControl, FormControlLabel, Radio, RadioGroup, Paper, Button, Typography, Grid } from '@mui/material';
// import styled from "@emotion/styled";

// const questions = [
//   {
//     number: "1",
//     question: "What is 2+5?",
//     optionLetter: ["A", "B", "C", "D"],
//     options: ["5", "8", "7", "6"],

//     answer: "7",
//   },
//   {
//     number: "2",
//     question: "what is 10 / 5?",
//     options: ["2", "3", "10", "8"],
//     optionLetter: ["A", "B", "C", "D"],

//     answer: "2",
//   },
//   {
//     number: "3",
//     question: "What is 2 + 2?",
//     options: ["3", "4", "5", "6"],
//     optionLetter: ["A", "B", "C", "D"],

//     answer: "4",
//   },
// ];

// const AttendQuizs = () => {
//   const [currentQuestion, setCurrentQuestion] = useState(0);
//   const [selectedAnswer, setSelectedAnswer] = useState('');
//   const [score, setScore] = useState(0);

//   const handleAnswerChange = (event) => {
//     setSelectedAnswer(event.target.value);
//   };

// const handleNextQuestion = () => {
//   if (selectedAnswer === questions[currentQuestion].answer) {
//     setScore(score + 1);
//   } else if (selectedAnswer === "") {
//     alert("Please select an answer before moving to the next question.");
//     return;
//   }

//   setSelectedAnswer("");
//   setCurrentQuestion(currentQuestion + 1);
// };

//   return (
//     <div>
//       {/* {currentQuestion < questions.length ? (
//         <Paper elevation={3} style={{ padding: '20px', margin: '20px' }}>
//           <Typography variant="h6">{questions[currentQuestion].question}</Typography>
//           <FormControl component="fieldset">
//             <RadioGroup value={selectedAnswer} onChange={handleAnswerChange}>
//               {questions[currentQuestion].options.map((option, index) => (
//                 <FormControlLabel key={index} value={option} control={<Radio />} label={option} />
//               ))}
//             </RadioGroup>
//           </FormControl>
//          <Grid conatiner spacing={2} >
//             <Grid item ></Grid>
//          </Grid>
//         </Paper>
//       ) : (
//         <Paper elevation={3} style={{ padding: '20px', margin: '20px' }}>
//           <Typography variant="h6">Quiz completed! Your score: {score}/{questions.length}</Typography>
//         </Paper>
//       )} */}

//       <Typography variant="h6" sx={{ marginBottom: "30px" }}>
//         {/* {questions[currentQuestion].question} */}
//          {questions[currentQuestion].number}:{" "}
//          {questions[currentQuestion].question}
//       </Typography>
//       <Typography sx={{ fontWeight: "bold", marginBottom: "30px" }}>
//         SELECT THE CORRECT ANSWER
//       </Typography>
//       <Grid conatiner spacing={2}>
//         <Grid item xs={12} md={12} lg={12}>
//           <FormControl fullWidth component="fieldset">
//             <RadioGroup value={selectedAnswer} onChange={handleAnswerChange}>
//               {questions[currentQuestion].options.map((option, index) => (
//                 <FormControlLabel
//                   key={index}
//                   value={option}
//                   style={{
//                     backgroundColor:
//                       selectedAnswer === option ? "lightgreen" : "white",
//                   }}
//                   control={<Radio />}
//                   label={option}
//                 />
//               ))}
//             </RadioGroup>
//           </FormControl>
//         </Grid>

//         <Grid item xs={12} md={12} lg={12} sx={{ mt: 1 }}>
//           <Button variant="contained" onClick={handleNextQuestion}>
//             Next
//           </Button>
//         </Grid>
//       </Grid>
//     </div>
//   );
// };

// export default AttendQuizs;

import React, { useState } from "react";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Paper,
  Button,
  Typography,
  Grid,
  Box,
} from "@mui/material";
import WestIcon from "@mui/icons-material/West";
import EastIcon from "@mui/icons-material/East";
const questions = [
  {
    number: "1",
    question:
      "@mui/icons-material/East@mui/icons-material /East@mui/icons-material/East@mui/icons-material/East@mui/icons-material /East@mui/icons-material/East@mui/icons-material/ East@mui/icons-material/East@mui/icons-material/East",
    optionLetter: ["A", "B", "C", "D"],
    options: ["5", "8", "7", "6"],
    answer: "7",
  },
  {
    number: "2",
    question: "What is 10 / 5?",
    options: ["2", "3", "10", "8"],
    optionLetter: ["A", "B", "C", "D"],
    answer: "2",
  },
  {
    number: "3",
    question: "What is 2 + 2?",
    options: ["3", "4", "5", "6"],
    optionLetter: ["A", "B", "C", "D"],
    answer: "4",
  },
  {
    number: "4",
    question: "What is 2 + 2?",
    options: ["3", "4", "5", "6"],
    optionLetter: ["A", "B", "C", "D"],
    answer: "4",
  },
];

const AttendQuizs = () => {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [selectedAnswers, setSelectedAnswers] = useState(
    Array(questions.length).fill("")
  );

  const handleAnswerChange = (event, index) => {
    const newSelectedAnswers = [...selectedAnswers];
    newSelectedAnswers[index] = event.target.value;
    setSelectedAnswers(newSelectedAnswers);
  };

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          margin: "10px",
        }}
      >
        <Button
          variant="contained"
          sx={{
            color: "black",
            background: "#eeeeee",
            "&:hover": { background: "#eeeeee" },
          }}
          startIcon={<WestIcon />}
        >
          Previous
        </Button>
        <Button
          variant="contained"
          endIcon={<EastIcon />}
          sx={{
            color: "black",
            background: "#eeeeee",
            "&:hover": { background: "#eeeeee" },
          }}
        >
          Next
        </Button>
      </Box>
      {questions.map((question, index) => (
        <Paper
          key={index}
          elevation={3}
          style={{ padding: "10px", margin: "10px" }}
        >
          <Typography
            variant="h6"
            sx={{ fontSize: "15px", fontWeight: "bold" }}
          >
            {question.number}: {question.question}
          </Typography>
          <FormControl component="fieldset">
            <Grid container>
              <RadioGroup
                value={selectedAnswers[index]}
                onChange={(event) => handleAnswerChange(event, index)}
              >
                {question.options.map((option, optionIndex) => (
                  <Grid item xs={6} sm={6} md={6} lg={6} mr={20}>
                    <FormControlLabel
                      key={optionIndex}
                      value={option}
                      control={<Radio size="small" />}
                      label={option}
                    />
                  </Grid>
                ))}
              </RadioGroup>
            </Grid>
          </FormControl>
        </Paper>
      ))}
      <Box sx={{ display: "flex", justifyContent: "flex-end", margin: "10px" }}>
        <Button variant="contained" type="sumbit">
          Sumbit
        </Button>
      </Box>
      {/* <Paper elevation={3} style={{ padding: "20px", margin: "20px" }}>
        <Button
          variant="contained"
          onClick={() => setCurrentQuestion(currentQuestion + 1)}>
          Next
        </Button>
      </Paper> */}
    </div>
  );
};

export default AttendQuizs;
