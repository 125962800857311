import { Box, Container, Grid, Typography } from "@mui/material";
import React, { useContext, useEffect } from "react";
import NavbarContext from "../../../context/NavbarContext";
import { Link } from "react-router-dom";
import { theme } from "../../../theme";

export const TermsConditions = () => {
  const { setShowNavBar } = useContext(NavbarContext);

  useEffect(() => {
    setShowNavBar(false);
  }, []);

  return (
    <>
      <Box bgcolor="whitesmoke">
        <Box
          sx={{
            background: theme.palette.secondary.main,
            height: 200,
          }}>
          <Typography
            sx={{
              textAlign: "center",
              fontSize: { xs: "25px", sm: "28px", md: "50px" },
              fontWeight: "bold",
              color: "#000000",
              padding: "15px 0",
            }}>
            Terms of Service
          </Typography>
          <Typography
            sx={{
              textAlign: "center",
              fontWeight: "bold",
              color: theme.palette.primary.dark,
              padding: "15px 0",
            }}>
            <Link to="/" style={{ textDecoration: "none" }}>
              Home
            </Link>{" "}
            / Terms of Service
          </Typography>
        </Box>
        <Container>
          <Grid container py={4}>
            <Typography variant="subtitle1" ml={2}>
              These Terms and Conditions ("Terms") govern your use of the
              Learning Management System Software ("LMS Software") provided by
              WEBSPRUCE ("Company") for the purpose of accessing and managing
              educational content and resources. By accessing or using the LMS
              Software, you agree to comply with these Terms. Please read them
              carefully.
            </Typography>
            <Grid itemxs={12} md={12} lg={12} m="10px 0">
              <ol>
                <li
                  style={{ fontSize: 20, fontWeight: 600, marginTop: "20px" }}>
                  Acceptance of Terms
                </li>

                <Typography variant="subtitle1" mt={2}>
                  By using the LMS Software, you agree to be bound by these
                  Terms. If you do not agree with these Terms, please do not use
                  the LMS Software.
                </Typography>

                <li
                  style={{ fontSize: 20, fontWeight: 600, marginTop: "20px" }}>
                  User Accounts
                </li>

                <ul style={{ marginTop: "20px" }}>
                  <li>
                    You must create a user account to use the LMS Software.
                  </li>
                  <li>
                    You are responsible for maintaining the confidentiality of
                    your account credentials.
                  </li>
                  <li>
                    You are responsible for all activities that occur under your
                    account.
                  </li>
                </ul>

                <li
                  style={{ fontSize: 20, fontWeight: 700, marginTop: "20px" }}>
                  Access and Use of the LMS Software
                </li>

                <ul style={{ marginTop: "20px" }}>
                  <li>
                    The Company grants you a non-exclusive, non-transferable,
                    revocable license to use the LMS Software solely for
                    educational and instructional purposes.
                  </li>
                  <li>
                    You agree not to use the LMS Software for any unlawful or
                    prohibited purpose.
                  </li>
                  <li>
                    You may not sublicense, lease, sell, or transfer your rights
                    to access the LMS Software.
                  </li>
                  <li>
                    You may not attempt to decompile, reverse engineer,
                    disassemble, or hack the LMS Software.
                  </li>
                </ul>

                <li
                  style={{ fontSize: 20, fontWeight: 700, marginTop: "20px" }}>
                  Content Ownership
                </li>
                <ul style={{ marginTop: "20px" }}>
                  <li style={{ marginTop: "20px" }}>
                    The Company retains all rights, title, and interest in the
                    LMS Software, including all related intellectual property
                    rights.
                  </li>
                  <li>
                    You retain all rights to your content uploaded to the LMS
                    Software.
                  </li>
                  <li>
                    You grant the Company a non-exclusive, worldwide,
                    royalty-free license to use, modify, and distribute your
                    content for the purpose of providing and improving the LMS
                    Software.
                  </li>
                </ul>

                <li
                  style={{ fontSize: 20, fontWeight: 700, marginTop: "20px" }}>
                  User Content
                </li>
                <ul style={{ marginTop: "20px" }}>
                  <li>
                    You are solely responsible for the content you upload,
                    create, or share through the LMS Software.
                  </li>
                  <li>
                    You agree not to upload, share, or distribute content that
                    is offensive, defamatory, or violates the rights of others.
                  </li>
                </ul>

                <li
                  style={{ fontSize: 20, fontWeight: 700, marginTop: "20px" }}>
                  Privacy
                </li>
                <ul style={{ marginTop: "20px" }}>
                  <li>
                    The Company will collect and use your personal information
                    in accordance with our Privacy Policy.
                  </li>
                  <li>
                    You consent to the use of your personal information as
                    described in the Privacy Policy.
                  </li>
                </ul>

                <li
                  style={{ fontSize: 20, fontWeight: 700, marginTop: "20px" }}>
                  Termination
                </li>
                <ul style={{ marginTop: "20px" }}>
                  <li>
                    The Company may terminate or suspend your access to the LMS
                    Software at any time, with or without cause.
                  </li>
                  <li>
                    You may terminate your account by contacting the Company.
                  </li>
                </ul>

                <li
                  style={{ fontSize: 20, fontWeight: 700, marginTop: "20px" }}>
                  Disclaimers
                </li>
                <ul style={{ marginTop: "20px" }}>
                  <li>
                    The LMS Software is provided "as is" without warranties of
                    any kind, either express or implied.
                  </li>
                  <li>
                    The Company does not guarantee that the LMS Software will be
                    error-free, uninterrupted, or secure.
                  </li>
                </ul>

                <li
                  style={{ fontSize: 20, fontWeight: 700, marginTop: "20px" }}>
                  Limitation of Liability
                </li>
                <ul style={{ marginTop: "20px" }}>
                  <li>
                    The Company shall not be liable for any indirect,
                    incidental, special, consequential, or punitive damages.
                  </li>
                  <li>
                    The maximum liability of the Company shall be limited to the
                    fees paid by you for the LMS Software.
                  </li>
                </ul>

                <li
                  style={{ fontSize: 20, fontWeight: 700, marginTop: "20px" }}>
                  Changes to Terms
                </li>
                <ul style={{ marginTop: "20px" }}>
                  <li>
                    The Company may update or modify these Terms at any time. It
                    is your responsibility to review these Terms periodically.
                  </li>
                </ul>

                <li
                  style={{ fontSize: 20, fontWeight: 700, marginTop: "20px" }}>
                  Contact Information
                </li>
                <ul style={{ marginTop: "20px" }}>
                  <li>
                    If you have any questions or concerns about these Terms,
                    please contact us at support@webspruce.com.
                  </li>
                </ul>
                <Typography variant="subtitle1" mt={2}>
                  By using the LMS Software, you acknowledge that you have read,
                  understood, and agreed to these Terms and Conditions.
                </Typography>
              </ol>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};
