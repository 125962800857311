import Axios from "axios";
import "react-toastify/dist/ReactToastify.css";

import { toast } from "react-toastify";

export const API_PREFIX = process.env.REACT_APP_API_PREFIX;

const client = Axios.create({
  baseURL: API_PREFIX,
});

client.interceptors.request.use(
  function (config) {
    const access_token = window.localStorage.getItem("access_token");

    if (access_token) {
      config.headers["X-auth-token"] = `bearer ${access_token}`;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

client.interceptors.response.use(
  function (response) {
    if (
      (response.status === 200 &&
        response.data.message &&
        ["post", "put", "delete"].includes(response.config.method)) ||
      (response.status === 201 &&
        response.data.message &&
        ["post", "put", "delete"].includes(response.config.method))
    ) {
      toast.success(response.data.message.split("_").join(" "));
    }
    return response;
  },
  function (error) {
    if (error.response.status === 401) {
      window.localStorage.removeItem("access_token");
      window.localStorage.removeItem("user-customer-webspruce");
      window.location.href = "/";
    }
    toast.error(error?.response.data.message);

    return Promise.reject(error);
  }
);

export default client;
