import React, { useContext } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import FormInput from "../../components/FormInput";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import {
  Box,
  Button,
  Checkbox,
  Container,
  Divider,
  FormControlLabel,
  FormLabel,
  Grid,
  ListItem,
  ListItemIcon,
  ListItemText,
  Radio,
  RadioGroup,
  Switch,
  Typography,
  TextField,
  styled,
  IconButton,
  Toolbar,
  Tooltip,
  InputLabel,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import GroupAddOutlinedIcon from "@mui/icons-material/GroupAddOutlined";
import {
  PhonelinkLockOutlined,
  ArrowForwardIosRounded,
} from "@mui/icons-material";
import { Stack } from "react-bootstrap";
import { get, post } from "../../api/apiMethods";
import urls from "../../api/urlContants";
import { CourseContext, EnqueryBookdata } from "../../context/CourseContext";
import FormSelect from "../form/FormSelect";
import { useState } from "react";
import { getStates, getCountry } from "country-state-picker";
import countriecode from "countries-code";
import { useEffect } from "react";
import PayPalButton from "../customer/PayPalButton";
import { BASE_URL } from "../../api/clients";
import { useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import UserContext from "../../context/UserContext";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import CloseIcon from "@mui/icons-material/Close";
import Towpiece from "../../assets/Images/two-capsule.png";
import { theme } from "../../theme";

const styles = {
  text: {
    fontWeight: "bold",
    fontSize: "14px",
    color: "black",
    paddingLeft: "10px",
  },
};

const Form = styled(Box)(() => ({
  // padding: "20px 40px",
  borderRadius: "20px",
  // marginTop: 20,
  // background: "white",
  width: "100%",
  margin: "0 auto",
  zIndex: 998,
  boxShadow:
    "box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
}));

const Phone = styled(PhoneInput)(() => ({
  ".PhoneInputInput": {
    padding: "11px 11px",
    borderRadius: "20px",
    border: "1px solid #e0e0e0",
    marginght: "10px",
    marginBottom: "25px",
    width: "70px",
  },
  ".PhoneInputCountry": {
    marginBottom: "20px",
  },
  ".PhoneInputInput:focus-visible": {
    outline: `1px solid ${theme.palette.primary.dark}`,
  },
}));

const Lable = styled(Stack)(() => ({
  color: "#000000",
  marginTop: "10px",
  fontSize: "14px",
  fontWeight: "bold",
}));

const programmes = {
  "Online Instructor-Led": "intructorLedSchedule",
  Classroom: "classRoomSchedule",
};

export const StudentCheckout = () => {
  const { id } = useParams();

  const { user, setUser } = useContext(UserContext);
  const navigate = useNavigate();
  const [rendered, setRendered] = useState(false);
  const [paywith, setPaywith] = useState("paypal");
  const [value, setValue] = React.useState();
  const { enqueryBookdata } = React.useContext(EnqueryBookdata);
  const [loading, setLoading] = useState(false);
  const [allCountries, setAllCountries] = React.useState(
    require("countries-cities").getCountries()
  );
  const [oneCourse, setOneCourse] = useState({});
  const [countriesQuery, setCountriesQuery] = React.useState(
    require("countries-code")
  );
  const [coupan, setCoupan] = useState([]);
  const [couponCodeEntered, setCouponCodeEntered] = useState(true);
  const [code, setCode] = useState("");
  const [countryWithCode, setCoutryWithCode] = React.useState(
    countriesQuery.allCountriesList()
  );
  const [states, setStates] = React.useState([]);
  const [coupanType, setCoupanType] = useState("");
  const [coupanValue, setCoupanValue] = useState("");
  // console.log(coupanValue, "coupanValue");
  // console.log(coupanType, "coupanType");
  const [show, setShow] = useState(false);
  const [success, setSuccess] = useState(false);
  const [ErrorMessage, setErrorMessage] = useState("");
  const [orderID, setOrderID] = useState("");
  const [gst, setGst] = useState("");
  const [subTotal, setSubTotal] = useState("");
  const [totalCost, setTotalCost] = useState("");
  useEffect(() => {
    setRendered(true);
  }, []);

  const onsiteFormik = useFormik({
    initialValues: {
      name: user?.name,
      email: user?.email?.address,
      contactNumber: user?.mobile,
      address: user?.address?.addressLineOne,
      country: "India",
      state: user?.address?.state,
      pinCode: "",
      paymentMethod: "phonepe",
      check: false,
      otherEmail: "",
      otherContactNumber: 0,
    },
    validationSchema: Yup.object({
      name: Yup.string().required().label("Name").max(150),
      email: Yup.string().email().required().label("Email").max(150),
      contactNumber: Yup.number().required().label("Contact Number").min(10),
      country: Yup.string().required().label("Country"),
      state: Yup.string().required().label("State"),
      address: Yup.string().required().label("Address"),
      pinCode: Yup.string().min(6).max(6).required().label("Pin code"),
      paymentMethod: Yup.string().required().label("Payment Method"),
      check: Yup.boolean()
        .required()
        .default(true)
        .label("Buying for someone else"),
      otherEmail: Yup.string()
        .nullable()
        .when("check", {
          is: (check) => check === true,
          then: () =>
            Yup.string().required().label("Other person email address"),
          otherwise: () => Yup.string().label("Other person email address"),
        }),
      otherContactNumber: Yup.number()
        .nullable()
        .when("check", {
          is: (check) => check === true,
          then: () =>
            Yup.number().required().label("Other person contact number"),
          otherwise: () => Yup.number().label("Other person contact number"),
        }),
    }),
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const body = {
          paymentMethod: values.paymentMethod,
          itemDetails: {
            courseId: id,
            programmeName: "Online Self-Paced",
            programmeDetails: { scheduleId: enqueryBookdata?.getEnquery?._id },
          },
          customerDetails: {
            name: values.name,
            email: values.email,
            contactNumber: values.contactNumber,
            country: values.country,
            state: values.state,
            pinCode: values.pinCode,
            address: values.address,
            currency: "INR",
            forSomeoneElse: values.check,
            othersDetails: {
              email: values.otherEmail,
              contactNumber: values.otherContactNumber,
            },
          },
          coupanCode: code,
        };

        const res = await post(`${urls.payment.order}`, body);

        window.location.href = res.data.meta.redirectUrl;
        return setLoading(false);

        if (body.paymentMethod === "paypal") {
          var token = "";
          var links = res.data.result.links;
          for (var i = 0; i < links.length; i++) {
            if (links[i].rel === "approval_url") {
              token = links[i].href.split("EC-", 2)[1];
            }
          }
          setOrderID(token);
          return setShow(true);
          setLoading(false);
        } else {
          let order = res.data.result;
          var options = {
            key: "rzp_test_39ltPNm3sMZuzV",
            amount: order.amount,
            currency: "INR",
            name: "Aphiya Learning PVT LTD",
            description: "Course purchase",
            order_id: order.id,
            prefill: {
              name: values.name,
              email: values.email,
              contact: values.contactNumber,
            },
            notes: {
              address: "LVR infra, Rajajinagar, Bangalore, Karnataka",
            },
            theme: {
              color: theme.palette.secondary.main,
            },
            handler: async function (response) {
              // console.log(response, "==================");
              navigate("/payment-success");
            },
          };

          const rzp1 = new window.Razorpay(options);
          rzp1.open();
          rzp1.on("payment.failed", function (response) {
            navigate("/payment-failed");
            console.log("Payment", response);
          });
        }
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    },

    validateOnBlur: false,
    enableReinitialize: true,
  });

  const createOrder = async (data, actions) => {
    return orderID;
  };

  // check Approval
  const onApprove = (data, actions) => {
    // console.log(data, actions, "data=======================================");
    return actions.order.capture().then(function (details) {
      const { payer } = details;
      setSuccess(true);
    });
  };

  //capture likely error
  const onError = (data, actions) => {
    setErrorMessage("An Error occured with your payment ");
  };

  useEffect(() => {
    if (success) {
      alert("Payment successful!!");
      console.log("Order successful . Your order id is--", orderID);
    }
  }, [success]);

  React.useEffect(() => {
    if (!onsiteFormik.values.country) {
      return;
    }
    let countryCode = countryWithCode.filter(
      (countryCode) =>
        countryCode.country_name_en === onsiteFormik.values.country
    )[0].alpha2;
    setStates(getStates(`${countryCode.toLowerCase()}`));
  }, [onsiteFormik.values.country]);

  // -----------------Gst count
  const Amount = (enqueryBookdata?.earlyBirdPrice * 18) / 100;
  const PriceTotal = enqueryBookdata?.earlyBirdPrice + Amount;
  useEffect(() => {
    const getCourse = async () => {
      try {
        const res = await get(`${urls.course.getOnevalue}/${id}`);

        setOneCourse(res.data.result.data);
        console.log("setOneCourse", res.data.result.data);
      } catch (error) {
        console.log(error);
      }
    };
    getCourse();
  }, []);
  const currentDate = new Date();
  const earlyBirdEndDate = new Date(
    oneCourse && oneCourse.selfPacedInfo
      ? oneCourse.selfPacedInfo.earlyBirdEndDate
      : ""
  );

  let priceToDisplay;

  if (earlyBirdEndDate > currentDate) {
    priceToDisplay =
      oneCourse && oneCourse.selfPacedInfo
        ? oneCourse.selfPacedInfo.earlyBirdPrice
        : "";
  } else {
    priceToDisplay =
      oneCourse && oneCourse.selfPacedInfo
        ? oneCourse.selfPacedInfo.standardPrice
        : "";
  }
  const HandleCheckCoupan = async () => {
    if (!code) {
      setCouponCodeEntered(false);
      return;
    }
    setCouponCodeEntered(true);
    try {
      const res = await get(`${urls.coupan.checkCoupan}`, {
        params: {
          code,
          courseId: id,
          amount: priceToDisplay,
        },
      });
      // console.log(res, "rrrrrrrrrrr");
      if (res) {
        setCoupanType(res.data.result.coupanType);
        setCoupanValue(res.data.result.value);
      }
    } catch (error) {
      console.log(error);
      setCode("");
      setCoupanType("");
      setCoupanValue("");
    }
  };

  const HandleRemoveCoupan = () => {
    setCode("");
    setCoupanType("");
    setCoupanValue("");
  };

  useEffect(() => {
    if (coupanType == "Percentage") {
      const discount = priceToDisplay * (coupanValue / 100);
      const subTotal = priceToDisplay - discount;
      setSubTotal(subTotal);
      const gst = subTotal * (18 / 100);
      setGst(gst);
      const totalCost = subTotal + gst;
      // console.log(totalCost, "----totalCost");
      setTotalCost(totalCost);
      // console.log(priceToDisplay, "priceToDisplay");
    } else {
      const subTotal = priceToDisplay - coupanValue;
      const gst = subTotal * (18 / 100);
      const totalCost = subTotal + gst;

      if (subTotal < 0) {
        setCode("");
        setCoupanType("");
        setCoupanValue("");
        toast.error("This Coupan is cannot be applied");
      } else {
        setSubTotal(subTotal);
        setGst(gst);
        setTotalCost(totalCost);
      }
    }
  }, [HandleCheckCoupan]);

  return (
    <>
      <PayPalScriptProvider
        options={{
          components: "buttons",
          clientId:
            "AYr26Z54PG_A2N2w5GMU-oXRGGTsLK6VyU-wSPj7W55biRyjiuM318Ov7YDMHzIJle5zi6dd133rsdXw",
        }}
      >
        <Box
          sx={{
            // height: "calc( 100dvh - 64px )",
            // height: "100vh",
            width: "100%",
            overflow: "hidden",
            position: "relative",
          }}
        >
          <img
            src={Towpiece}
            alt="loading..."
            height={100}
            style={{
              display: "flex",
              justifyContent: "flex-end",
              position: "absolute",
              right: -131,
              top: -19,
              zIndex: -9,
              transform: "rotate(155deg)",
            }}
          />
          <img
            src={Towpiece}
            alt="loading..."
            height={100}
            style={{
              display: "flex",
              justifyContent: "flex-end",
              position: "absolute",
              left: -70,
              bottom: -35,
              zIndex: -9,
              transform: "rotate(-30deg)",
              // opacity: 0.4,
              // transform: "translate(47px, 27px)",
            }}
          />

          <Grid container spacing={4} p={4}>
            <Grid item xs={12} sm={12} md={7} lg={8}>
              <Form component={"form"} onSubmit={onsiteFormik.handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Typography
                      variant="h6"
                      mb={1}
                      style={{ color: theme.palette.primary.dark }}
                    >
                      Enter Your Billing Details
                    </Typography>
                    <Divider
                      sx={{
                        marginBottom: "10px",
                        color: theme.palette.secondary.dark,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <FormLabel sx={styles.text}>Full Name*</FormLabel>
                    <FormInput formik={onsiteFormik} name="name" type="text" />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <FormLabel sx={styles.text}>Email*</FormLabel>
                    <FormInput
                      formik={onsiteFormik}
                      name="email"
                      type="email"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <FormLabel sx={styles.text}>Country*</FormLabel>
                    <FormSelect
                      formik={onsiteFormik}
                      options={allCountries?.map((c) => ({
                        label: c,
                        value: c,
                      }))}
                      name="country"
                      type="text"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <FormLabel sx={styles.text}>State*</FormLabel>
                    <FormSelect
                      formik={onsiteFormik}
                      options={states?.map((c) => ({ label: c, value: c }))}
                      name="state"
                      type="text"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <FormLabel sx={styles.text}>Contact No*</FormLabel>
                    <Box
                      gap={2}
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Phone
                        formik={onsiteFormik}
                        name="contactNumber"
                        international
                        countryCallingCodeEditable={false}
                        defaultCountry="IN"
                        value={value}
                        onChange={setValue}
                      />
                      <FormInput formik={onsiteFormik} name="contactNumber" />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={2}>
                    <FormLabel sx={styles.text}>Pincode*</FormLabel>
                    <FormInput formik={onsiteFormik} name="pinCode" />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={4}>
                    <FormLabel sx={styles.text}>GST No.</FormLabel>
                    <FormInput formik={onsiteFormik} name="gst" />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <FormLabel sx={styles.text}>Address*</FormLabel>
                    <FormInput
                      formik={onsiteFormik}
                      name="address"
                      type="text"
                      multiline={true}
                      rows={3}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <ListItem sx={{ padding: 0 }}>
                      <ListItemIcon>
                        <GroupAddOutlinedIcon />
                      </ListItemIcon>
                      <ListItemText
                        id="switch-list-label-wifi"
                        primary="I am buying this course for someone else"
                      />
                      <Checkbox
                        edge="end"
                        name="check"
                        inputProps="aria-labelledby"
                        checked={onsiteFormik.values.check}
                        onChange={(e) =>
                          onsiteFormik.setFieldValue("check", e.target.checked)
                        }
                        sx={{
                          color: theme.palette.primary.main,
                          "&.Mui-checked": {
                            color: theme.palette.primary.main,
                          },
                        }}
                      />
                    </ListItem>
                    {onsiteFormik.values.check && (
                      <Box sx={{ m: 1, p: 1 }}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={6} md={6}>
                            <InputLabel htmlFor="otherEmail" sx={styles.text}>
                              Email
                            </InputLabel>

                            <FormInput
                              size="small"
                              name="otherEmail"
                              placeholder="Enter Email"
                              formik={onsiteFormik}
                              required
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={6}>
                            <InputLabel
                              htmlFor="otherContactNumber"
                              sx={styles.text}
                            >
                              Contact Number
                            </InputLabel>

                            <FormInput
                              size="small"
                              placeholder="Enter Mobile No."
                              name="otherContactNumber"
                              formik={onsiteFormik}
                              required
                              fullWidth
                            />
                          </Grid>
                        </Grid>
                      </Box>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <ListItem sx={{ padding: 0 }}>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="paymentMethod"
                        value={onsiteFormik.values.paymentMethod}
                        onChange={onsiteFormik.handleChange}
                      >
                        {/* <FormControlLabel
                    value="paypal"
                    control={<Radio />}
                    label="Paypal"
                    id="paypal"
                    htmlFor="paypal"
                    checked={onsiteFormik.values.paymentMethod === "paypal"}
                  /> */}
                        {/* <FormControlLabel
                          value="razorpay"
                          control={
                            <Radio
                              style={{ color: theme.palette.primary.main }}
                            />
                          }
                          label="Razorpay"
                          id="razorpay"
                          htmlFor="razorpay"
                          checked={
                            onsiteFormik.values.paymentMethod === "razorpay"
                          }
                        /> */}
                      </RadioGroup>
                    </ListItem>
                  </Grid>
                  {/* <Grid item xs={12} sm={12} md={12} lg={12}>
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    endIcon={<ArrowForwardIosRounded />}
                    sx={{
                      width: "100%",
                      backgroundColor: "#747DE8",

                      "&:hover": {
                        backgroundColor: "#747DE8",
                      },

                      "&.MuiButtonBase-root": { borderRadius: "5px" },
                    }}
                    loading={loading}
                  >
                    Proceed to pay
                  </LoadingButton>
                </Grid> */}
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    {show && onsiteFormik.values.paymentMethod === "paypal" ? (
                      <PayPalButtons
                        style={{ layout: "vertical" }}
                        createOrder={createOrder}
                        onApprove={onApprove}
                        onError={onError}
                      />
                    ) : null}
                  </Grid>
                </Grid>
              </Form>
            </Grid>
            {/* ===============Biling section================ */}
            <Grid item xs={12} sm={12} md={5} lg={4}>
              <Box
                component={"form"}
                onSubmit={onsiteFormik.handleSubmit}
                sx={{
                  border: "1px solid gray",
                  borderRadius: "20px",

                  mt: { xs: 0, sm: 0, md: 12, lg: 12 },
                  zIndex: 9,
                }}
                p={4}
              >
                <Typography variant="h6" mb={1}>
                  Your Total Basket is{" "}
                  <Typography
                    variant="span"
                    fontWeight={600}
                    fontSize="25px"
                    color={theme.palette.primary.dark}
                    mb={2}
                  >
                    ₹{totalCost}
                  </Typography>
                </Typography>
                <Typography variant="span" mb={2} color="gray" component="div">
                  {oneCourse.title}
                </Typography>
                <Lable>
                  <Typography variant="body" mb={2}>
                    Subtotal
                  </Typography>{" "}
                  <Typography variant="body" mb={2}>
                    ₹{priceToDisplay}
                  </Typography>
                </Lable>
                <Box sx={{ marginTop: "15px" }}>
                  <div
                    style={{
                      display: "flex",
                      // justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <TextField
                      id="outlined-basic"
                      name="code"
                      value={code}
                      label="discount code"
                      // required
                      variant="outlined"
                      size="small"
                      // onChange={(e) => setCode(e.target.value)}
                      onChange={(e) => {
                        setCouponCodeEntered(true);
                        setCode(e.target.value);
                      }}
                    />

                    <Tooltip title="Apply Coupan">
                      <Button
                        onClick={HandleCheckCoupan}
                        // sx={{ marginLeft: "220px", position: "fixed" }}
                        variant="contained"
                        size="small"
                        sx={{
                          marginLeft: "45px",
                        }}
                      >
                        APPLY
                      </Button>
                    </Tooltip>
                  </div>
                  {coupanType && (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          marginTop: "10px",
                          padding: "15px 0",
                          color: "#fff",
                          width: "400px",
                        }}
                      >
                        <Typography
                          sx={{
                            color: `${theme.palette.primary.dark}`,
                            fontSize: { xs: "12px", sm: "14px", md: "16px" },
                          }}
                        >
                          {coupanType && coupanValue
                            ? coupanType == "Percentage"
                              ? `${coupanValue}% OFF`
                              : `₹${coupanValue} FLAT`
                            : ""}{" "}
                          COUPON APPLYED SUCCESSFULLY!
                        </Typography>
                      </div>
                      <Tooltip title="Remove Coupan">
                        <IconButton
                          color="error"
                          aria-label="delete"
                          size="medium"
                          sx={{
                            marginLeft: "15px",
                            "&:hover": {
                              backgroundColor: "transparent",
                              opacity: 0.5,
                            },
                          }}
                          onClick={HandleRemoveCoupan}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  )}
                </Box>
                {!couponCodeEntered && (
                  <Typography
                    sx={{ color: "red", marginTop: "5px", fontSize: "12px" }}
                  >
                    discount code is required.
                  </Typography>
                )}
                <Box
                  sx={{
                    // background: "#CACACA",
                    background: "#d9dcfd",
                    // background: theme.palette.secondary.main,
                    padding: "20px",
                    marginTop: "20px",
                    borderRadius: "20px",
                  }}
                >
                  <Lable
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="span" mb={2}>
                      Subtotal______________
                    </Typography>{" "}
                    <Typography variant="span" mb={2}>
                      ₹ {subTotal}
                    </Typography>
                  </Lable>
                  <Lable
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="span" mb={2}>
                      GST______________
                    </Typography>{" "}
                    <Typography variant="span" mb={2}>
                      {typeof gst === "number" && !isNaN(gst)
                        ? gst.toFixed(2)
                        : "Invalid GST"}
                      (18%)
                    </Typography>
                  </Lable>
                  <Lable
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="span" mb={2}>
                      Total______________
                    </Typography>{" "}
                    <Typography variant="span" mb={2}>
                      ₹ {totalCost}
                    </Typography>
                  </Lable>
                </Box>
                <Grid item xs={12} sm={12} md={12} lg={12} my={2}>
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    endIcon={<ArrowForwardIosRounded />}
                    sx={{
                      width: "100%",
                      // "&.MuiButtonBase-root": { borderRadius: 20 },
                    }}
                    loading={loading}
                  >
                    Proceed to pay
                  </LoadingButton>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </PayPalScriptProvider>
    </>
  );
};
