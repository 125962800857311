import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Container,
  Grid,
  TextField,
  Typography,
  Box,
  styled,
  Stack,
  Paper,
} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Profilename from "./Profilename";
import UserContext from "../../../context/UserContext";
import { put } from "../../../api/apiMethods";
import { urls } from "../../../api/urlsContants";
import { LoadingButton } from "@mui/lab";
import { theme } from "../../../theme";

const Expand = styled(TextField)(({ theme }) => ({
  marginTop: "2px",
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

const Label = styled("label")(() => ({
  fontWeight: "bold",
  fontSize: "14px",
  paddingLeft: "10px",
}));

const MainBox = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
}));

const Heading = styled(Typography)(() => ({
  fontWeight: 700,
  fontSize: "25px",
  whiteSpace: "nowrap",
  marginLeft: "17px",
  marginBottom: "20px",
  color: `${theme.palette.primary.main}`,
}));

const SubHeading = styled(Typography)(() => ({
  fontSize: "12px",
  fontWeight: 800,
  color: `${theme.palette.primary.main}`,
  marginTop: "8px",
  cursor: "pointer",
}));

const Profile = styled(Avatar)(() => ({
  fontSize: "3.5rem",
  height: "85px",
  width: "85px",
  borderRadius: "50%",
  "&:hover": {
    height: "85px",
    width: "85px",
    borderRadius: "50%",
  },
}));

const Box1 = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginTop: "10px",
}));

const HeaderData = styled(Stack)(() => ({
  display: "flex",
  flexDirection: "column",
  textAlign: "center",
  alignItems: "center",
}));

const AccountSetting = () => {
  const { user, setUser } = useContext(UserContext);
  let formData1 = useContext(Profilename);
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    let user = window.localStorage.getItem("user-customer-webspruce");
    if (user) {
      setUser(JSON.parse(user));
      setFormData(JSON.parse(user));
    }
  }, []);

  const [formData, setFormData] = useState({
    fullname: user.name || "",
    email: "",
    contactno: "",
    city: "",
    state: "",
    address: "",
  });
  const [selectedFile, setSelectedFile] = useState(null);
  const fileInputRef = useRef(null);
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedFile(file);
    }
  };
  const handlePhotoClick = () => {
    fileInputRef.current.click();
  };
  useEffect(() => {
    if (user) {
      setFormData({
        fullname: user.name || "",
        email: user.email?.address || "",
        contactno: user.mobile || "",
        city: user.address?.city || "",
        state: user.address?.state || "",
        address: user.address?.addressLineOne || "",
      });
    }
  }, [user]);
  const handleClose = () => {
    setOpen(false);
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      const body = {
        name: formData.fullname,
        address: formData.address,
        contactNumber: formData.contactno,
        city: formData.city,
        state: formData.state,
      };
      const formdata = new FormData();
      formdata.append("bodyData", JSON.stringify(body));
      formdata.append("file", selectedFile);
      const res = await put(`${urls.user.updateInfo}`, formdata);
      if (res) {
        window.localStorage.setItem(
          "user-customer-webspruce",
          JSON.stringify(res.data.result)
        );
        setUser(res.data.result);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
    setData((prevData) => [...prevData, formData]);
    formData1.setFirstName(formData.firstname);
    formData1.setLastName(formData.lastname);
    handleClose();
  };
  return (
    <>
      <Container>
        <Box1>
          <Paper sx={{ maxWidth: 800, px: 3 }}>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={1} sx={{ mt: "10px" }}>
                <Grid
                  item
                  xs={12}
                  md={12}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "auto",
                  }}>
                  <MainBox>
                    <Heading>Account Settings</Heading>
                    <HeaderData>
                      <Profile
                        alt="profile pic"
                        src={
                          selectedFile
                            ? URL.createObjectURL(selectedFile)
                            : user?.image
                        }
                      />
                      <SubHeading onClick={handlePhotoClick}>
                        Change Photo
                      </SubHeading>
                    </HeaderData>
                    <input
                      type="file"
                      accept="image/*"
                      style={{ display: "none" }}
                      ref={fileInputRef}
                      onChange={handleFileChange}
                    />
                  </MainBox>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Label htmlFor="">Full Name</Label>
                  <br />
                  <Expand
                    name="fullname"
                    value={formData.fullname}
                    size="small"
                    required
                    fullWidth
                    placeholder="Mherab"
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Label htmlFor="">Email</Label>
                  <br />
                  <Expand
                    disabled={true}
                    name="email"
                    position="relative"
                    size="small"
                    type={"emial"}
                    value={formData.email}
                    fullWidth
                    placeholder="MehrabBozorgi.Business@gmail.com"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Label htmlFor="">Contact Number</Label>
                  <br />
                  <Expand
                    inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                    name="contactno"
                    size="small"
                    type=""
                    value={formData.contactno}
                    required
                    fullWidth
                    placeholder=""
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Label htmlFor="">State</Label>
                  <br />
                  <Expand
                    name="state"
                    value={formData.state}
                    size="small"
                    required
                    fullWidth
                    placeholder="Karnataka"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Label htmlFor="">City</Label>
                  <br />
                  <Expand
                    name="city"
                    size="small"
                    value={formData.city}
                    required
                    fullWidth
                    placeholder="Channapatna"
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Label htmlFor="">Address</Label>
                  <br />
                  <Expand
                    name="address"
                    size="small"
                    value={formData.address}
                    required
                    fullWidth
                    placeholder="3306 Zbonack isle"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  sx={{ display: "flex", margin: "20px 0" }}>
                  <LoadingButton
                    variant="contained"
                    type="submit"
                    loading={loading}>
                    Update
                  </LoadingButton>
                </Grid>
              </Grid>
            </form>
          </Paper>
        </Box1>
      </Container>
    </>
  );
};

export default AccountSetting;
