export const pages = [
  // {
  //   id: "1",
  //   path: "/",
  //   name: "Home",
  // },
  {
    id: "1",
    path: "/allcourse",
    name: "Explore",
  },

  {
    id: "2",
    path: "/my-courses",
    name: "My Courses",
  },
  {
    id: "3",
    path: "/certificates",
    name: "Certificates",
  },
  {
    id: "4",
    path: "/refer",
    name: "Refer",
  },
  {
    id: "5",
    path: "/live",
    name: "Live",
  },
];

export const menuitems = [
  {
    id: "1",
    path: "/allcourse",
    name: "Explore",
  },
  {
    id: "2",
    path: "/my-courses",
    name: "My Courses",
  },
  {
    id: "3",
    path: "/certificates",
    name: "Certificates",
  },
  {
    id: "4",
    path: "/refer",
    name: "Refer",
  },
  {
    id: "5",
    path: "/live",
    name: "Live",
  },
  // {
  //   id: "5",
  //   path: "/bel",
  //   name: "Notification",
  // },
];

export const settings = [
  {
    id: "1",
    path: "/account-settings",
    name: "Account Settings",
  },
  {
    id: "2",
    path: "/certificates",
    name: "Certificates",
  },
  {
    id: "3",
    path: "/invoice",
    name: "Invoice",
  },
  {
    id: "4",
    path: "/change-password",
    name: "Change Password",
  },
  {
    id: "5",
    path: "/logout",
    name: "Logout",
  },
];
export const exploreDetails = [
  {
    id: "1",
    path: "/allcourse",
    name: "All Course",
  },
  {
    id: "2",
    path: "/option2",
    name: "Option 2",
  },
];
