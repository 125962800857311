import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Box, Button, Grid, styled, TextField } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import { LoadingButton } from "@mui/lab";
import * as Yup from "yup";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { post } from "../../../api/apiMethods";
import { urls } from "../../../api/urlsContants";
import NavbarContext from "../../../context/NavbarContext";
import Laptop from "../../../assets/Images/laptop.png";
import towPiece from "../../../assets/Images/towpiece.png";
import { useFormik } from "formik";
import FormInput from "../../form/FormInput";
import logo from "../../../assets/Images/websaprucee.png";
import { RippelVideoButton } from "../../RippelVideoButton";
import { theme } from "../../../theme";

const SigninBox = styled(Box)(() => ({
  background: theme.palette.secondary.dark,
  display: "flex",
  justifyContent: "space-between",
  borderRadius: "50px",
  padding: "0px 0px",
  marginTop: "20px",
}));

const SigninButton = styled(Button)(() => ({
  color: "#fff",
  borderRadius: "20px",
  margin: "5px",
  backgroundColor: theme.palette.primary.dark,
  padding: "10px 50px",
  fontSize: "15px",
}));

const RippelBox = styled(Box)(() => ({
  margin: "auto",
  transform: "rotate(-20deg)",
}));

const Main = styled(Box)(() => ({
  height: "100vh",
  width: "100%",
  display: "flex",
  overflow: "hidden",
}));

const LeftBox = styled(Box)(() => ({
  display: "flex",
  height: "100%",
  backgroundImage: `url(${towPiece})`,
  backgroundRepeat: "no-repeat",
  backgroundPositionY: "100%",
}));

const RightBox = styled(Box)(() => ({
  position: "relative",
  backgroundImage: `url(${Laptop})`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  backgroundPositionY: "center",
  justifyContent: "end",
}));

const inputForm = {
  width: { xs: "100%", sm: "80%", md: "80%", lg: "60%" },
  margin: "30px auto",
  padding: "0 20px",
};

export const Signup = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [verifyLoading, setVerifyLoading] = useState(false);
  const [receivedCode, setReceivedCode] = useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setReceivedCode("");
  };
  const handleSendCode = async () => {
    setLoading(true);
    // e.preventDefault();

    try {
      const sendCode = await post(`${urls.auth.sendEmailConfirmationCode}`, {
        name: formik.values.name,
        email: formik.values.email,
      });
      console.log(sendCode, "sendCode");
      setLoading(false);
      setOpen(true);
      // if (sendCode.data.responseCode === "OK") {

      // }
      // handleClickOpen();
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
    setLoading(false);
  };
  const handleVerifyCode = async () => {
    setVerifyLoading(true);
    // e.preventDefault();
    try {
      const sendCode = await post(`${urls.auth.verifyEmailCode}`, {
        receivedCode: receivedCode,
        email: formik.values.email,
      });
      if (sendCode.data.responseCode === "OK") {
        formik.handleSubmit();
      }
      console.log(sendCode, "message");
      setVerifyLoading(false);
      setReceivedCode("");
    } catch (error) {
      console.log(error);
    }
    setVerifyLoading(false);
  };
  const { setShowNavBar } = useContext(NavbarContext);
  useEffect(() => {
    setShowNavBar(false);
    return () => setShowNavBar(true);
  }, []);

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      mobile: "",
      password: "",
    },

    validationSchema: Yup.object({
      name: Yup.string().required().label("Name").max(150),
      email: Yup.string().email().required().label("Email").max(150),
      mobile: Yup.string().required().label("Contact Number").min(10),
      password: Yup.string().required().label("Password").max(32),
    }),
    onSubmit: async (values) => {
      setLoading(true);

      try {
        const body = {
          email: values.email,
          name: values.name,
          mobile: values.mobile,
          password: values.password,
        };
        const res = await post(urls.user.createAccount, body);
        if (res.status === 201) {
          window.localStorage.setItem(
            "user-customer-webspruce",
            JSON.stringify(res.data.result.user)
          );

          window.localStorage.setItem(
            "access_token",
            res.data.result.access_token
          );

          navigate("/allcourse");
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    },

    validateOnBlur: false,
  });

  return (
    <>
      <ToastContainer />
      <Main>
        <LeftBox
          sx={{
            width: { xs: "100%", sm: "100%", md: "50%", lg: "40%" },
          }}
        >
          <Grid container sx={inputForm}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <img
                src={logo}
                height={100}
                width={300}
                style={{ objectFit: "contain" }}
              />
              <SigninBox>
                <Button>
                  <Link
                    to="/"
                    style={{
                      textDecoration: "none",
                      color: "#fff",
                      marginLeft: "10px",
                    }}
                  >
                    Sign in
                  </Link>
                </Button>
                <SigninButton variant="contained">Sign up</SigninButton>
              </SigninBox>
              <Box component="form">
                <Grid item xs={12} sm={12} md={12} lg={12} mt={3}>
                  <FormInput
                    formik={formik}
                    name="name"
                    label="Full Name"
                    size="small"
                    type="text"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} my={1}>
                  <FormInput
                    formik={formik}
                    name="email"
                    type="email"
                    label="Email"
                    size="small"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} my={1}>
                  <FormInput
                    label="Contact Number"
                    formik={formik}
                    name="mobile"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} my={1}>
                  <FormInput
                    formik={formik}
                    name="password"
                    type="password"
                    label="Enter Password"
                    size="small"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} my={2}>
                  <LoadingButton
                    variant="contained"
                    loading={loading}
                    fullWidth
                    onClick={() => {
                      handleSendCode();
                    }}
                  >
                    Sign Up
                  </LoadingButton>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </LeftBox>
        <RightBox
          sx={{
            display: { xs: "none", sm: "none", md: "block", lg: "block" },
            backgroundPositionX: { md: "center", lg: "35px" },
            width: { xs: "0", sm: "65%", md: "65%", lg: "60%" },
          }}
        >
          <RippelBox
            sx={{
              position: "absolute",
              top: { md: "42%", lg: "42%" },
              left: { md: "36%", lg: "42%" },
            }}
          >
            <RippelVideoButton />
          </RippelBox>
        </RightBox>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Verification Code Send to your Email</DialogTitle>
          <DialogContent>
            <TextField
              label="Code Verification"
              fullWidth
              variant="standard"
              name="receivedCode"
              type="number"
              onChange={(e) => setReceivedCode(e.target.value)}
              value={receivedCode || ""}
            />
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              size="small"
              sx={{
                backgroundColor: "maroon",
                "&:hover": {
                  backgroundColor: "red",
                },
              }}
              onClick={handleClose}
            >
              Cancel
            </Button>
            <LoadingButton
              variant="contained"
              size="small"
              loading={verifyLoading}
              onClick={handleVerifyCode}
            >
              Submit
            </LoadingButton>
          </DialogActions>
        </Dialog>
      </Main>
    </>
  );
};
