import React, { useContext, useEffect } from "react";
import NavbarContext from "../../../context/NavbarContext";
import { Box, Container, Grid, Typography, styled } from "@mui/material";
import { Link } from "react-router-dom";
import { theme } from "../../../theme";

const Content = styled(Typography)(() => ({
  color: "#555",
  lineHeight: "24px",
  margin: "15px 0",
  fontSize: "15px",
  textAlign: "justify",
}));

export const ReturnPolicy = () => {
  const { setShowNavBar } = useContext(NavbarContext);

  useEffect(() => {
    setShowNavBar(false);
  }, []);

  return (
    <>
      <Box bgcolor="whitesmoke">
        <Box
          sx={{
            background: theme.palette.secondary.main,
            height: 200,
          }}
        >
          <Typography
            sx={{
              textAlign: "center",
              fontSize: "50px",
              fontWeight: "bold",
              color: "#000000",
              padding: "15px 0",
            }}
          >
            Refund Policy
          </Typography>
          <Typography
            sx={{
              textAlign: "center",
              fontWeight: "bold",
              color: theme.palette.primary.dark,
              padding: "15px 0",
            }}
          >
            <Link to="/" style={{ textDecoration: "none" }}>
              Home
            </Link>{" "}
            / Refund Policy
          </Typography>
        </Box>
        <Container>
          <Grid container py={4}>
            <Content>
              Thank you for choosing webspruce! We appreciate your commitment to
              enhancing your skills with our software. Before you proceed with
              your purchase, please review our refund policy carefully.
            </Content>
            <Grid itemxs={12} md={12} lg={12} m="10px 0">
              <ol>
                <li
                  style={{ fontSize: 20, fontWeight: 600, marginTop: "20px" }}
                >
                  Refund Policy:
                </li>

                <Typography variant="subtitle1" mt={2}>
                  Due to the nature of our digital courses and the immediate
                  access to proprietary software upon purchase, we regret to
                  inform you that we do not offer any refunds under any
                  circumstances. Once the software has been accessed or
                  downloaded, the sale is considered final.
                </Typography>
                <li
                  style={{ fontSize: 20, fontWeight: 700, marginTop: "20px" }}
                >
                  Cancellation Policy:
                </li>

                <Typography variant="subtitle1" mt={2}>
                  As our courses provide immediate access to valuable digital
                  content, there is no option for cancellation once the purchase
                  is completed. We strongly encourage you to thoroughly evaluate
                  the course details, including the software features and
                  compatibility, before making your purchase decision.
                </Typography>

                <li
                  style={{ fontSize: 20, fontWeight: 700, marginTop: "20px" }}
                >
                  Non-transferable License:
                </li>
                <Typography variant="subtitle1" mt={2}>
                  Please note that the license for the software included in the
                  course is non-transferable. The access provided is solely for
                  the purchaser and may not be shared, resold, or transferred to
                  another individual or entity.
                </Typography>

                <li
                  style={{ fontSize: 20, fontWeight: 700, marginTop: "20px" }}
                >
                  Changes to Refund Policy:
                </li>
                <Typography variant="subtitle1" mt={2}>
                  We reserve the right to modify this refund policy at any time
                  without prior notice. Any changes will be effective
                  immediately upon posting on our website. By proceeding with
                  the purchase of any courses, you acknowledge and agree to the
                  terms outlined in this refund policy. Thank you for your
                  understanding. We look forward to supporting you on your
                  learning journey with Webspruce.
                </Typography>
              </ol>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};
