import {
  Box,
  Button,
  CardActionArea,
  Container,
  Grid,
  Paper,
  Rating,
  Tab,
  Tabs,
  Typography,
  styled,
} from "@mui/material";
import image from "../../assets/Images/courseimg.png";
import React, { useState, useEffect, useContext } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import CheckIcon from "@mui/icons-material/Check";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import AccessTimeRoundedIcon from "@mui/icons-material/AccessTimeRounded";
import { useParams, useNavigate } from "react-router-dom";
import { post, get } from "../../api/apiMethods";
import urls from "../../api/urlContants";
import { urls as url } from "../../api/urlsContants";
import { Link } from "react-scroll";
import dayjs from "dayjs";
import { KeyboardArrowRight } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import UserContext from "../../context/UserContext";
import { toast } from "react-toastify";
import { theme } from "../../theme";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}

const Time = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "10px",
  right: "0",
  backgroundColor: theme.palette.primary.main,
  borderTopLeftRadius: "5px",
  borderBottomLeftRadius: "5px",
  width: "130px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "10px 0",
  height: "40px",
  boxShadow: "0px 8px 6px -6px black",
  clipPath: "polygon(15% 50%, 0% 0%, 100% 0%,100% 100%,  0% 100%)",
}));

const Headding = styled(Typography)(({}) => ({
  color: theme.palette.primary.main,
  fontWeight: "bold",
}));

const MainHeading = styled(Typography)(() => ({
  color: theme.palette.primary.main,
  fontWeight: "bold",
  textTransform: "capitalize",
}));

const Content = styled(Typography)(() => ({
  color: "black",
  margin: "20px 0",
  fontSize: "18px",
}));

const SubTitle = styled(Typography)(() => ({
  color: "gray",
  margin: "10px 0",
  fontSize: "17px",
}));

const Star = styled(Rating)(() => ({
  fontSize: "20px",
  margin: "0px 10px",
}));

const TabBox = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  padding: "0 10px",
}));

const Hours = styled(Typography)(() => ({
  display: "flex",
  fontWeight: "bold",
  fontSize: "13px",

  alignItems: "center",
  color: theme.palette.primary.main,
}));

const RatingBox = styled(Box)(() => ({
  display: "flex",
  marginTop: "10px",
  alignItems: "center",
}));

const TabList = styled(List)(() => ({
  width: "100%",
  color: "black",
  display: "flex-end",
}));

const TimeTypography = styled(Typography)(() => ({
  fontWeight: "bold",
  fontSize: "16px",
  display: "flex",
  flexDirection: "column-reverse",

  color: "#fff",
}));

const RatingBox2 = styled(Box)(() => ({
  display: "flex",
  marginTop: "10px",
  alignItems: "center",
  justifyContent: "space-between",
}));
const DesTypography = styled(Typography)(() => ({
  display: "-webkit-box",
  fontSize: "13px",
  margin: "5px 0",
  WebkitLineClamp: 1,
  textOverflow: "ellipsis",
  overflow: "hidden",
  WebkitBoxOrient: "vertical",
  height: 20,
}));

const CourseTitle = styled(Typography)(() => ({
  color: theme.palette.primary.main,
  fontWeight: "bold",
  display: "-webkit-box",
  margin: "5px 0",
  WebkitLineClamp: 1,
  overflow: "hidden",
  WebkitBoxOrient: "vertical",
}));

const AllCourseContent = () => {
  const { setUser, user } = useContext(UserContext);
  const { id } = useParams();
  const [tabValue, setTabValue] = useState(0);
  const [feedback, setFeedback] = useState([]);
  const [courses, setCourses] = useState([]);
  const [extraCourse, setExtraCourse] = useState([]);
  const [rating, setRating] = useState(0);
  const [averageRatings, setAverageRatings] = useState({});
  const navigate = useNavigate();
  const [enrolling, setEnrolling] = useState(false);
  const [showBuyButton, setShowBuyButton] = useState(false);

  useEffect(() => {
    if (id) {
      if (user?.courses?.length) {
        setShowBuyButton(
          !user.courses.filter((c) => c.courseId?._id === id)[0]
        );
      } else {
        setShowBuyButton(true);
      }
    }
  }, [id]);

  useEffect(() => {
    const getCourse = async () => {
      try {
        const response = await get(`${urls.course.getOnevalue}/${id}`);
        setCourses(response.data.result.data);
        setFeedback(response.data.result.data.feedbacks);
      } catch (error) {
        console.log(error);
      }
    };
    getCourse();
  }, [id]);

  useEffect(() => {
    if (feedback && Array.isArray(feedback)) {
      let totalRatings = 0;
      let totalFeedbacks = feedback.length;

      feedback.forEach((course) => {
        totalRatings += Number(course.rating);
      });
      const averageRating =
        totalFeedbacks > 0 ? totalRatings / totalFeedbacks : 0;
      setRating(averageRating);
    } else {
      setRating(0);
    }
  }, [feedback]);

  useEffect(() => {
    const ratings = {};
    extraCourse.forEach((course) => {
      if (course.feedbacks && Array.isArray(course.feedbacks)) {
        const totalRatings = course.feedbacks.reduce(
          (sum, feedback) => sum + Number(feedback.rating),
          0
        );
        const averageRating = totalRatings / (course.feedbacks.length || 1);

        ratings[course._id] = averageRating;
      } else {
        ratings[course._id] = 0;
      }
    });

    setAverageRatings(ratings);
  }, [extraCourse]);

  useEffect(() => {
    const getCourse = async () => {
      try {
        const response = await get(url.course.getList);
        const coursesData = response.data.result.data;
        const filteredCourses = coursesData.filter(
          (course) =>
            course.menu.activated && course.menu.name === courses?.menu?.name
        );
        const exceptData = filteredCourses.filter(
          (course) => course._id !== id
        );

        setExtraCourse(exceptData);
      } catch (error) {
        console.error(error);
      }
    };
    getCourse();
  }, [id, courses]);

  const currentDate = new Date();
  const earlyBirdEndDate = new Date(
    courses && courses.selfPacedInfo
      ? courses.selfPacedInfo.earlyBirdEndDate
      : ""
  );

  let priceToDisplay;
  if (earlyBirdEndDate > currentDate) {
    priceToDisplay =
      courses && courses.selfPacedInfo
        ? courses.selfPacedInfo.earlyBirdPrice
        : "";
  } else {
    priceToDisplay =
      courses && courses.selfPacedInfo
        ? courses.selfPacedInfo.standardPrice
        : "";
  }

  const handleEnroll = async () => {
    setEnrolling(true);
    try {
      const body = {
        itemDetails: {
          courseId: id,
        },
      };
      const { data } = await post(urls.payment.order, body);
      window.localStorage.setItem(
        "user-customer-webspruce",
        JSON.stringify(data.result)
      );
      setUser(data.result);
      setEnrolling(false);
      toast.success("Course enrolled successfully!");
      navigate("/my-courses");
    } catch (error) {
      console.log(error);
    }
    setEnrolling(false);
  };

  const handleNavigate = () => {
    if (courses.isPaid == true) {
      navigate(`/studentCheckout/${courses._id}`);
    } else {
      handleEnroll();
    }
  };

  const handleNavigateToCourse = (id, course) => {
    const selectedCourse = extraCourse.find((course) => course._id === id);
    if (selectedCourse) {
      navigate(`/course/content/${id}`);
    }
  };
  useEffect(() => {
    handleNavigateToCourse();
  }, []);

  const goToCoursePlaylist = () => {
    navigate(`/course/material/${id}`);
  };
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  return (
    <Container maxWidth="xl">
      <Grid
        container
        rowSpacing={6}
        columnSpacing={2}
        mt={2}
        p={{ xs: "20px 25px", sm: "20px 15px", md: "20px 45px" }}>
        <Grid container rowSpacing={6} columnSpacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Box sx={{ padding: "0 10px" }} id="course">
              <MainHeading
                variant="h6"
                sx={{
                  marginTop: { xs: "24px", sm: "24px", md: "0px" },
                  fontSize: { xs: "25px", sm: "28px", md: "32px" },
                }}>
                {courses?.title}
              </MainHeading>
              <Content>{courses?.description}</Content>

              <RatingBox>
                <SubTitle>{rating}</SubTitle>
                <Star name="read-only" value={rating} readOnly />
              </RatingBox>
              <SubTitle>Subscribe now to access this course.</SubTitle>
              {!showBuyButton ? (
                <Button
                  onClick={goToCoursePlaylist}
                  variant="contained"
                  sx={{
                    marginTop: "15px",
                  }}
                  size="small">
                  Start Course
                </Button>
              ) : (
                <LoadingButton
                  loading={enrolling}
                  variant="contained"
                  sx={{
                    marginTop: "15px",
                  }}
                  onClick={handleNavigate}>
                  {courses?.isPaid == true
                    ? `Buy This Course For ₹${priceToDisplay}`
                    : "Enroll Now"}
                </LoadingButton>
              )}
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            sx={{
              display: "flex",
              justifyContent: {
                xs: "center",
                sm: "center",
                md: "center",
                lg: "center",
              },
            }}>
            <TabBox>
              <Box>
                <Card sx={{ borderRadius: "50px" }}>
                  {" "}
                  <Tabs
                    value={tabValue}
                    onChange={handleTabChange}
                    aria-label="basic tabs example"
                    variant="scrollable"
                    scrollButtons
                    allowScrollButtonsMobile>
                    <Tab label="Course Overview" {...a11yProps(0)} />
                    <Tab label="What You'll Learn" {...a11yProps(1)} />
                  </Tabs>
                </Card>
              </Box>
              <Box sx={{ alignSelf: "flex-start" }}>
                <TabPanel value={tabValue} index={0}>
                  <List sx={{ width: "100%", color: "black" }}>
                    {courses && courses.courseDetails
                      ? courses?.courseDetails?.overview?.map((value, i) => (
                          <ListItem key={i} sx={{ p: "5px 0" }}>
                            <ListItemIcon sx={{ textAlign: "start" }}>
                              <KeyboardArrowRight
                                fontSize="small"
                                sx={{ color: theme.palette.primary.dark }}
                              />
                            </ListItemIcon>
                            <ListItemText
                              primary={value.point}
                              primaryTypographyProps={{
                                fontSize: 16,
                                fontWeight: "medium",
                              }}
                            />
                          </ListItem>
                        ))
                      : ""}
                  </List>
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                  <TabList>
                    {courses && courses.courseDetails
                      ? courses?.courseDetails?.benefits?.map((value, i) => (
                          <ListItem key={i} sx={{ p: "5px 0" }}>
                            <ListItemIcon sx={{ color: "inherit" }}>
                              <CheckIcon
                                sx={{ color: theme.palette.primary.dark }}
                              />
                            </ListItemIcon>
                            <ListItemText
                              primary={value.point}
                              primaryTypographyProps={{
                                fontSize: 16,
                                fontWeight: "medium",
                              }}
                            />
                          </ListItem>
                        ))
                      : ""}
                  </TabList>
                </TabPanel>
              </Box>
            </TabBox>
          </Grid>
        </Grid>

        {!extraCourse.length ? null : (
          <Grid item xs={12} sm={12} lg={12}>
            <Headding variant="h5" sx={{ margin: "15px 0" }}>
              Courses You may be Interested In
            </Headding>

            <Grid container spacing={2}>
              {extraCourse.map((value, i) => (
                <Grid item xs={12} sm={6} md={4} lg={3} key={i}>
                  <Link
                    activeClass="active"
                    to="course"
                    spy={true}
                    smooth={true}
                    duration={500}
                    offset={-150}
                    onClick={() => handleNavigateToCourse(value._id, value)}>
                    <Card
                      sx={{
                        boxShadow: "5",
                        height: "100%",
                      }}>
                      <CardActionArea>
                        <CardMedia
                          height={180}
                          component="img"
                          image={
                            value.thumbnailImage ? value.thumbnailImage : image
                          }
                          alt="green iguana"
                        />

                        <CardContent sx={{ padding: "10px 10px" }}>
                          <Time>
                            <TimeTypography>
                              {value.isPaid === true
                                ? `₹${
                                    dayjs(Date.now()).diff(
                                      new Date(
                                        value?.selfPacedInfo?.earlyBirdEndDate
                                      ),
                                      "minutes"
                                    ) < 0
                                      ? value?.selfPacedInfo?.earlyBirdPrice
                                      : value?.selfPacedInfo?.standardPrice
                                  }`
                                : "Free"}
                              {value.isPaid === true ? (
                                dayjs(Date.now()).diff(
                                  new Date(
                                    value?.selfPacedInfo?.earlyBirdEndDate
                                  ),
                                  "minutes"
                                ) < 0 ? (
                                  <s
                                    style={{
                                      color: "#fff",
                                      fontSize: "12px",
                                      fontWeight: "normal",
                                    }}>
                                    {`₹${value.selfPacedInfo.standardPrice}`}
                                  </s>
                                ) : null
                              ) : (
                                ""
                              )}
                            </TimeTypography>
                          </Time>
                          <CourseTitle variant="h6">{value.title}</CourseTitle>
                          <DesTypography>{value.description}</DesTypography>

                          <RatingBox2>
                            <Box sx={{ display: "flex" }}>
                              <Typography
                                sx={{
                                  color: theme.palette.primary.main,
                                  fontSize: "13px",
                                }}>
                                {averageRatings[value._id]}
                              </Typography>
                              {averageRatings[value._id] === 0 ||
                              averageRatings[value._id] ? (
                                <Star
                                  id={value._id}
                                  name="simple-controlled"
                                  value={averageRatings[value._id]}
                                  readOnly
                                />
                              ) : null}

                              <Typography sx={{ fontSize: "13px" }}>
                                (
                                {value && value.feedbacks
                                  ? value.feedbacks.length
                                  : "0"}
                                )
                              </Typography>
                            </Box>

                            <Hours>
                              <AccessTimeRoundedIcon
                                sx={{
                                  fontSize: "13px",
                                  marginRight: "5px",
                                }}
                              />
                              {value.courseHours}hrs
                            </Hours>
                          </RatingBox2>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Link>
                </Grid>
              ))}
            </Grid>
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

export default AllCourseContent;
