import React from "react";
import { Box, Button, Fab, Paper } from "@mui/material";
import PlayCircleRoundedIcon from "@mui/icons-material/PlayCircleRounded";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";

export const RippelVideoButton = () => {
  return (
    <>
      {/* <div
        className="ripple"
        component="button"
        style={{
          borderRadius: "50%",
          width: "70px",
          height: "70px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <PlayCircleRoundedIcon sx={{ fontSize: 60, color: "#C38EDE" }} />
      </div> */}
      <Fab
        color="primary"
        className="ripple"
        aria-label="add"
        sx={{
          fontSize: 60,
          background: "#C38EDE",
          "&:hover": { background: "#C38EDE" },
        }}
      >
        <PlayArrowIcon />
      </Fab>
    </>
  );
};
