import React, { useRef, useState } from "react";
import {
  Box,
  Container,
  Paper,
  Typography,
  styled,
  Stack,
} from "@mui/material";
import { Link } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import {
  EmailIcon,
  FacebookIcon,
  LinkedinIcon,
  WhatsappIcon,
  FacebookShareButton,
  EmailShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
} from "react-share";
import { theme } from "../../../theme";

const Heading = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  textAlign: "center",
  color: theme.palette.primary.main,
}));

const Papers = styled(Paper)(() => ({
  marginTop: "20px",
  justifyContent: "center",
  alignItems: "center",
  display: "flex",
  padding: "20px",
}));

const Boxes = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: theme.spacing(2),
  [theme.breakpoints.down(700)]: {
    flexDirection: "column",
  },
}));

const IconContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  gap: 15,
  marginTop: "30px",
  fontSize: "30px",
}));

const Input = styled("input")(({ theme }) => ({
  border: `1px solid ${theme.palette.primary.main}`,
  borderRadius: "20px",
  marginBottom: "10px",
  padding: "10px",
  width: 400,
  position: "relative",
  [theme.breakpoints.down("sm")]: {
    width: 250,
  },
  [theme.breakpoints.down("xs")]: {
    width: 250,
  },
}));

const MainContainer = styled(Container)(() => ({
  background: theme.palette.grey[200],
  borderRadius: "20px",
  marginTop: "20px",
}));

const Invite = styled(Typography)(() => ({
  textAlign: "center",
  fontWeight: "600px",
  marginBottom: "20px",
}));

const LinkData = styled(Link)(() => ({
  textDecoration: "none",
  color: theme.palette.primary.main,
  fontWeight: "bold",
  fontSize: "14px",
  padding: "10px",
}));

const ReferFriend = () => {
  const inputRef = useRef(null);
  const theme = useTheme();

  const [shareLink, setShareLink] = useState(
    "https://www.linkedin.com/company/webspruce/mycompany/"
  );

  const handleCopyLink = () => {
    inputRef.current.select();
    document.execCommand("copy");
  };

  const handleChange = (e) => {
    setShareLink(e.target.value);
  };
  return (
    <>
      <MainContainer>
        <Box py={4}>
          <Heading variant="h5">Refer a Friend</Heading>
          <Papers elevation={0}>
            <Box>
              <Invite>Share your Invite</Invite>
              <Boxes>
                <Stack
                  direction="row"
                  spacing={2}
                  sx={{ display: "flex", justifyContent: "center" }}>
                  <Input
                    ref={inputRef}
                    type="text"
                    value={shareLink}
                    onChange={handleChange}
                  />
                  <LinkData onClick={handleCopyLink}>Copy Link</LinkData>
                </Stack>

                <IconContainer>
                  <FacebookShareButton url={`${shareLink}`}>
                    <FacebookIcon
                      url={`${shareLink}`}
                      size={45}
                      round
                      sx={{ borderRadius: "50%" }}
                    />
                  </FacebookShareButton>
                  <LinkedinShareButton url={`${shareLink}`}>
                    <LinkedinIcon
                      size={45}
                      round
                      sx={{ borderRadius: "50%" }}
                    />
                  </LinkedinShareButton>
                  <WhatsappShareButton url={`${shareLink}`}>
                    <WhatsappIcon
                      url={shareLink}
                      size={45}
                      round
                      sx={{ borderRadius: "50%" }}
                    />
                  </WhatsappShareButton>
                  <EmailShareButton url={`${shareLink}`}>
                    <EmailIcon
                      url={shareLink}
                      size={45}
                      round
                      sx={{ borderRadius: "50%" }}
                    />
                  </EmailShareButton>
                </IconContainer>
              </Boxes>
            </Box>
          </Papers>
        </Box>
      </MainContainer>
    </>
  );
};

export default ReferFriend;
