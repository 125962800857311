import client from "./clients";

export const get = async (url, options) => {
  const res = await client.get(url, options);
  return res;
};
export const post = async (url, body, options) => {
  const res = await client.post(url, body, options);
  return res;
};
export const del = async (url) => {
  const res = await client.delete(url);
  return res;
};
export const put = async (url, body, options) => {
  const res = await client.put(url, body, options);
  return res;
};
