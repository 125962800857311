import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { createContext, useContext, useEffect, useState } from "react";
import CustomNavbar from "./components/customer/navbar/CustomNavbar";
import AccountSetting from "./components/customer/accountsetting/AccountSetting";
import ReferFriend from "./components/customer/refer/ReferFriend";
import Invoices from "./components/customer/invoice/Invoices";
import Courses from "./components/customer/courses/Courses";
import LiveClass from "./components/customer/liveclass/LiveClass";
import Course from "./components/customer/Home/Course";
import Certificate from "./components/customer/certificarte/Certificate";
import Profilename from "./components/customer/accountsetting/Profilename";
import Loading from "./loadingPage";
import { Login } from "./components/page/auth/Login";
import "react-toastify/dist/ReactToastify.css";
import { Signup } from "./components/page/auth/Signup";
import VideoPlayer from "./components/VideoPlayer";
import AttendQuizs from "./components/AttendQuizs";
import NavbarContext from "./context/NavbarContext";
import AllCourses from "./components/customer/AllCourses";
import { ResetPassword } from "./components/customer/accountsetting/ResetPassword";
import UserContext from "./context/UserContext";
import PageNotFound from "./components/customer/PageNotFound";
import CourseContent from "./components/customer/CourseContent";
import { StudentCheckout } from "./components/customer/StudentCheckout";
import { CourseContext, EnqueryBookdata } from "./context/CourseContext";
import { PaymentFailure } from "./components/payment/PaymentFailure";
import { PaymentSuccess } from "./components/payment/PaymentSuccess";
import ResetPasswordLogin from "./components/page/auth/ResetPasswordLogin";
import { ToastContainer } from "react-toastify";
import { PrivacyPolicy } from "./components/page/privacy/PrivacyPolicy";
import { ReturnPolicy } from "./components/page/privacy/returnPolicy";
import { TermsConditions } from "./components/page/privacy/TermsConditions";
import Live from "./components/Live";
import { GoogleOAuthProvider } from "@react-oauth/google";
import AllDetails from "./components/AllDetails";
import AllCourseContent from "./components/customer/AllCourseContent";
import { get } from "./api/apiMethods";
import { urls } from "./api/urlsContants";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./theme";
import ForgotPassword from "./components/page/auth/ForgotPassword";
import PrivateRoute from "./navigation/PrivateRoute";
import Room from "./components/page/Room";
import PublicRoute from "./navigation/PublicRoute";

function App() {
  let [firstName, setFirstName] = useState();
  let [lastName, setLastName] = useState();
  const [showNavBar, setShowNavBar] = useState(true);
  const [user, setUser] = useState({ name: "Guest User" });
  const [enqueryBookdata, setEnqueryBookdata] = useState([]);
  const [isReady, setIsReady] = useState(false);

  const getCurrentUser = async () => {
    try {
      let { data } = await get(urls.user.getCurrentUser);
      window.localStorage.setItem(
        "user-customer-webspruce",
        JSON.stringify(data.result)
      );
      setUser(data.result);
      setIsReady(true);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    let user = window.localStorage.getItem("user-customer-webspruce");
    if (user) {
      getCurrentUser();
    } else {
      setUser({ name: "Guest User" });
      setIsReady(true);
    }
  }, []);

  console.log(showNavBar, "showNavBar=============");

  if (!isReady) return null;

  return (
    <>
      <ThemeProvider theme={theme}>
        <GoogleOAuthProvider clientId="424998668986-ka9k9ok4uuuo034q4bkbr5mrkc5d0f4m.apps.googleusercontent.com">
          <UserContext.Provider value={{ user, setUser }}>
            <Profilename.Provider
              value={{ firstName, setFirstName, lastName, setLastName }}
            >
              <EnqueryBookdata.Provider
                value={{ enqueryBookdata, setEnqueryBookdata }}
              >
                <NavbarContext.Provider value={{ showNavBar, setShowNavBar }}>
                  <Router>
                    {showNavBar ? <CustomNavbar /> : null}
                    <Routes>
                      <Route
                        path="/"
                        element={
                          <PublicRoute>
                            <Login />
                          </PublicRoute>
                        }
                      />
                      <Route
                        path="/account-settings"
                        element={
                          <PrivateRoute>
                            <AccountSetting />
                          </PrivateRoute>
                        }
                      />
                      <Route
                        path="/invoice"
                        element={
                          <PrivateRoute>
                            <Invoices />
                          </PrivateRoute>
                        }
                      />
                      <Route
                        path="/my-courses"
                        element={
                          <PrivateRoute>
                            <Courses />
                          </PrivateRoute>
                        }
                      />

                      <Route
                        path="/certificates"
                        element={
                          <PrivateRoute>
                            <Certificate />
                          </PrivateRoute>
                        }
                      />
                      <Route
                        path="/refer"
                        element={
                          <PrivateRoute>
                            <ReferFriend />
                          </PrivateRoute>
                        }
                      />

                      <Route
                        path="/allcourse"
                        element={
                          <PrivateRoute>
                            <AllCourses />
                          </PrivateRoute>
                        }
                      />
                      <Route
                        path="/option2"
                        element={
                          <PrivateRoute>
                            <LiveClass />
                          </PrivateRoute>
                        }
                      />

                      <Route
                        path="/live"
                        element={
                          <PrivateRoute>
                            <Live />
                          </PrivateRoute>
                        }
                      />
                      <Route
                        path="/room/:roomID"
                        element={
                          <PrivateRoute>
                            <Room />
                          </PrivateRoute>
                        }
                      />

                      <Route
                        path="/signup"
                        element={
                          <PublicRoute>
                            <Signup />
                          </PublicRoute>
                        }
                      />
                      <Route
                        path="/forgot-password"
                        element={<ForgotPassword />}
                      />
                      <Route
                        path="/resetPassword/:access_token"
                        element={<ResetPasswordLogin />}
                      />
                      <Route
                        path="/course/material/:id"
                        element={
                          <PrivateRoute>
                            <VideoPlayer />
                          </PrivateRoute>
                        }
                      />
                      <Route
                        path="/attendquizs"
                        element={
                          <PrivateRoute>
                            <AttendQuizs />
                          </PrivateRoute>
                        }
                      />
                      <Route
                        path="/change-password"
                        element={
                          <PrivateRoute>
                            <ResetPassword />
                          </PrivateRoute>
                        }
                      />

                      <Route path="*" element={<PageNotFound />} />

                      <Route
                        path="/studentCheckout/:id"
                        element={
                          <PrivateRoute>
                            <StudentCheckout />
                          </PrivateRoute>
                        }
                      />
                      <Route
                        path="/payment-failed"
                        element={
                          <PrivateRoute>
                            <PaymentFailure />
                          </PrivateRoute>
                        }
                      />
                      <Route
                        path="/payment-success"
                        element={
                          <PrivateRoute>
                            <PaymentSuccess />
                          </PrivateRoute>
                        }
                      />
                      <Route
                        path="/privacy-policy"
                        element={<PrivacyPolicy />}
                      />
                      <Route path="/refund-policy" element={<ReturnPolicy />} />
                      <Route
                        path="/terms-service"
                        element={<TermsConditions />}
                      />
                      <Route
                        // path="/course/content"
                        path="/course/content/:id"
                        element={
                          <PrivateRoute>
                            <AllCourseContent />
                          </PrivateRoute>
                        }
                      />
                    </Routes>
                  </Router>
                </NavbarContext.Provider>
              </EnqueryBookdata.Provider>
            </Profilename.Provider>
          </UserContext.Provider>
          <ToastContainer />
        </GoogleOAuthProvider>
      </ThemeProvider>
    </>
  );
}
export default App;
