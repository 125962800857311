import React, { useState, useContext } from "react";
import {
  Box,
  Container,
  Grid,
  Paper,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import { put } from "../../../api/apiMethods";
import { urls } from "../../../api/urlsContants";
import UserContext from "../../../context/UserContext";
import { LoadingButton } from "@mui/lab";
import { toast } from "react-toastify";
import { theme } from "../../../theme";
const MainBox = styled(Box)(() => ({
  mt: "30px",
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  alignContent: "center",
  padding: "20px",
}));

const Header = styled(Typography)(() => ({
  color: theme.palette.primary.main,
  fontWeight: "bold",
  fontSize: "1.5rem",
  textAlign: "center",
}));

export const ResetPassword = () => {
  const { user } = useContext(UserContext);
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    oldPassWord: "",
    newPassWord: "",
    confirmPassWord: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      !formData.oldPassWord ||
      !formData.newPassWord ||
      !formData.confirmPassWord
    ) {
      toast.error("Please fill the all password fields");
      return;
    }
    setLoading(true);

    try {
      const body = {
        mobile: user.mobile,
        oldPassWord: formData.oldPassWord,
        newPassWord: formData.newPassWord,
        password: formData.confirmPassWord,
      };
      const res = await put(`${urls.user.resetPassword}`, body);
      setLoading(false);
      setFormData({
        oldPassWord: "",
        newPassWord: "",
        confirmPassWord: "",
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
      setFormData({
        oldPassWord: "",
        newPassWord: "",
        confirmPassWord: "",
      });
    }
  };
  return (
    <>
      <Container>
        <MainBox>
          <Paper
            sx={{
              width: { xs: "100%", sm: "100%", md: "50%", lg: "50%" },
              padding: "30px",
            }}>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Header>Reset Password</Header>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} mt={2}>
                  <TextField
                    fullWidth
                    label="Old Password"
                    variant="outlined"
                    size="small"
                    name="oldPassWord"
                    value={formData.oldPassWord}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} mt={2}>
                  <TextField
                    fullWidth
                    label="New Password"
                    variant="outlined"
                    size="small"
                    name="newPassWord"
                    value={formData.newPassWord}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} mt={2}>
                  <TextField
                    fullWidth
                    label="Confirm Password"
                    variant="outlined"
                    name="confirmPassWord"
                    value={formData.confirmPassWord}
                    onChange={handleChange}
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} mt={2}>
                  <LoadingButton
                    loading={loading}
                    variant="contained"
                    size="small"
                    type="submit">
                    Submit
                  </LoadingButton>
                </Grid>
              </Grid>
            </form>
          </Paper>
        </MainBox>
      </Container>
    </>
  );
};
