import React, { useState } from "react";
import {
  Container,
  Fab,
  Grid,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
  styled,
  Tab,
  Toolbar,
  AppBar,
} from "@mui/material";
import { Tabs } from "@mui/material";
import { data } from "./Live";
import CustomNavbar from "../navbar/CustomNavbar";
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}
const Heading = styled(TableCell)(() => ({
  fontWeight: "bold",
  textAlign: "center",
}));
const Data = styled(TableCell)(() => ({
  textAlign: "center",
}));

const LiveClass = () => {
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  return (
    <>
      <Paper elevation={1}>
        <Grid container justifyContent="flex-start">
          <Grid item>
            <Box>
              <Tabs
                value={tabValue}
                onChange={handleTabChange}
                aria-label="basic tabs example"
              >
                <Tab label="Sheduled" {...a11yProps(0)} />
                <Tab label="Recorded" {...a11yProps(1)} />
              </Tabs>
            </Box>
          </Grid>
        </Grid>
      </Paper>

      <Container sx={{ overflow: "auto" }}>
        <br />

        <TabPanel value={tabValue} index={0}>
          <Table>
            <TableHead>
              <TableRow>
                <Heading>Course Name</Heading>
                <Heading>Start Date</Heading>
                <Heading>Start Time</Heading>
                <Heading>Approx.Duration</Heading>
                <Heading>Trainer</Heading>
                <Heading></Heading>
              </TableRow>
            </TableHead>

            <TableBody component={Paper} elevation={3}>
              {data.map((item, index) => (
                <TableRow key={index}>
                  <Data>{item.course}</Data>
                  <Data>{item.date}</Data>
                  <Data>{item.time}</Data>
                  <Data>{item.duration}</Data>
                  <Data>{item.trainer}</Data>
                  <Data>
                    <Button variant="contained">Join</Button>
                  </Data>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TabPanel>

        <TabPanel value={tabValue} index={1}>
          <Table>
            <TableHead>
              <TableRow>
                <Heading>Course Name</Heading>
                <Heading>Start Date</Heading>
                <Heading>Start Time</Heading>
                <Heading>Duration</Heading>
                <Heading>Trainer</Heading>
                <Heading></Heading>
              </TableRow>
            </TableHead>
            <TableBody component={Paper} elevation={3}>
              {data.map((item, index) => (
                <TableRow key={index}>
                  <Data>{item.course}</Data>
                  <Data>{item.date}</Data>
                  <Data>{item.time}</Data>
                  <Data>{item.duration}</Data>
                  <Data>{item.trainer}</Data>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TabPanel>
      </Container>
    </>
  );
};

export default LiveClass;
