import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import {
  Box,
  CardActionArea,
  Container,
  Grid,
  Button,
  styled,
  Paper,
} from "@mui/material";
import { coursedetails } from "./CourseDetails";
import { blueGrey } from "@mui/material/colors";
import AccessTimeRoundedIcon from "@mui/icons-material/AccessTimeRounded";
import Rating from "@mui/material/Rating";

const Design = styled(Typography)(() => ({
  color: "#7c4dff",
  fontSize: "12px",
  fontFamily: "Muli",
}));
const Title = styled(Typography)(() => ({
  textTransform: "uppercase",
  fontSize: "1rem",
  fontWeight: "bold",
  marginTop: "2%",
  fontFamily: "Muli",
}));

const Content = styled(Typography)(() => ({
  fontSize: "15px",
  color: blueGrey[500],
  fontFamily: "Muli",
}));

const Name = styled(Box)(() => ({
  fontFamily: "Muli",
  marginTop: "3%",
  fontWeight: 550,
  fontSize: "13px",
  justifyContent: "flex-start",
}));

const Star = styled(Rating)(() => ({
  fontSize: "20px",
}));

const Time = styled(Box)(() => ({
  position: "absolute",
  top: "2%",
  fontFamily: "Muli",
  right: "5%",
  backgroundColor: "white",
  border: "1px solid white",
  borderRadius: "2px",
  display: "flex",
  fontSize: "13px",
  gap: "5px",
}));
const Price = styled(Typography)(() => ({
  color: "#7c4dff",
  fontWeight: "bold",
  fontSize: "25px",
  fontFamily: "Muli",
  position: "absolute",
  right: "15px",
}));

export const CardComponent = ({ elem }) => {
  const [value, setValue] = React.useState(2);
  return (
    <>
      <Grid item xs={12} md={4} sm={6} lg={3}>
        <Card sx={{ boxShadow: "10", padding: 2 }}>
          <CardActionArea>
            <CardMedia
              component="img"
              image={elem.thumbnailImage}
              alt="green iguana"
            />
            <CardContent sx={{ paddingLeft: "0px" }}>
              <Time>
                <AccessTimeRoundedIcon
                  sx={{ fontSize: "medium", marginTop: "2%" }}
                />
                <Typography>{elem.time}</Typography>
              </Time>
              <Design>{elem.design}</Design>
              <Title gutterBottom variant="h5" component="div">
                {elem.title}
              </Title>
              <Content variant="body2" color="text.secondary">
                {elem.description}
              </Content>
              <Box sx={{ display: "flex", marginTop: 1 }}>
                <Typography color="#7c4dff">4.5</Typography>
                <Star
                  name="simple-controlled"
                  value={value}
                  fontSize="20px"
                  onChange={(event, newValue) => {
                    setValue(newValue);
                  }}
                />
                <Typography>(123)</Typography>
              </Box>
              <Box sx={{ display: "flex" }}>
                <Name>{elem.name}</Name>
                <Price>{elem.price}</Price>
              </Box>
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
    </>
  );
};

const Course = () => {
  return (
    <>
      <div style={{ marginTop: "2%" }}></div>
      <Container>
        <Grid container spacing={2}>
          {coursedetails.map((elem, index) => (
            <CardComponent key={index} elem={elem} />
          ))}
        </Grid>
      </Container>
    </>
  );
};
export default Course;
