import React, { useState, useEffect, useContext } from "react";
import { Box, Button, Grid, Typography, styled } from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { useFormik } from "formik";
import * as Yup from "yup";

import { post, get } from "../../../api/apiMethods";
import { urls } from "../../../api/urlsContants";
import FormInput from "../../form/FormInput";
import NavbarContext from "../../../context/NavbarContext";
import Laptop from "../../../assets/Images/laptop.png";
import towPiece from "../../../assets/Images/towpiece.png";
import { RippelVideoButton } from "../../RippelVideoButton";
import { theme } from "../../../theme";

const Main = styled(Box)(() => ({
  height: "100vh",
  width: "100%",
  display: "flex",
  overflow: "hidden",
  justifyContent: "space-between",
}));

const LeftBox = styled(Box)(() => ({
  display: "flex",
  height: "100%",
  backgroundImage: `url(${towPiece})`,
  backgroundRepeat: "no-repeat",
  backgroundPositionY: "100%",
}));

const RightBox = styled(Box)(() => ({
  position: "relative",
  backgroundImage: `url(${Laptop})`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  backgroundPositionY: "center",
  justifyContent: "end",
}));

const RippelBox = styled(Box)(() => ({
  margin: "auto",
  transform: "rotate(-20deg)",
}));

const inputForm = {
  width: { xs: "100%", sm: "100%", md: "80%", lg: "60%" },
  margin: "150px auto",
  padding: "0 20px",
};

const ResetPasswordLogin = () => {
  const navigate = useNavigate();
  const { access_token } = useParams();
  const [loading, setLoading] = useState(false);
  const { setShowNavBar } = useContext(NavbarContext);

  useEffect(() => {
    setShowNavBar(false);

    return () => setShowNavBar(true);
  }, []);

  const formik = useFormik({
    initialValues: {
      newpassword: "",
      conpassword: "",
    },

    validationSchema: Yup.object({
      newpassword: Yup.string().required().label("Password").max(32),
      conpassword: Yup.string().required().label("Password").max(32),
    }),
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const body = {
          newPassword: values.newpassword,
          confirmPassword: values.conpassword,
          token: access_token,
        };

        const { data, status } = await post(
          urls.user.resetForgotPasswordInLink,
          body
        );
        setLoading(false);
        navigate("/");
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    },
    validateOnBlur: false,
  });
  return (
    <>
      <Main>
        <LeftBox
          sx={{
            width: { xs: "100%", sm: "100%", md: "50%", lg: "40%" },
          }}
        >
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2} sx={inputForm}>
              <Grid item xs={12} md={12}>
                <Typography variant="h5" fontWeight={600} my={2}>
                  Change Password
                </Typography>
                <Typography
                  variant="body"
                  textAlign="center"
                  sx={{
                    fontSize: {
                      xs: "13px",
                      sm: "14px",
                      md: "16px",
                    },
                  }}
                >
                  Enter your new password!
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <FormInput
                  formik={formik}
                  name="newpassword"
                  type="password"
                  label="New Password"
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <FormInput
                  formik={formik}
                  name="conpassword"
                  type="password"
                  label="Confirm Password"
                  size="small"
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12}>
                <LoadingButton
                  type="submit"
                  variant="contained"
                  loading={loading}
                  sx={{
                    width: "100%",
                    borderRadius: "20px",
                    backgroundColor: theme.palette.primary.dark,
                  }}
                >
                  Submit
                </LoadingButton>
              </Grid>
            </Grid>
          </form>
        </LeftBox>
        <RightBox
          sx={{
            width: { xs: "100%", sm: "65%", md: "65%", lg: "60%" },
            display: { xs: "none", sm: "none", md: "block", lg: "block" },
            backgroundPositionX: { md: "center", lg: "35px" },
          }}
        >
          <RippelBox
            sx={{
              position: "absolute",
              top: { md: "42%", lg: "42%" },
              left: { md: "36%", lg: "42%" },
            }}
          >
            <RippelVideoButton />
          </RippelBox>
        </RightBox>
      </Main>
    </>
  );
};

export default ResetPasswordLogin;
