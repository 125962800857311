import {
  Box,
  Button,
  Container,
  FormControl,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  styled,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { get } from "../api/apiMethods";
import moment from "moment";
import FilterListIcon from "@mui/icons-material/FilterList";

import { Link, useNavigate } from "react-router-dom";

import { theme } from "../theme";
import { urls } from "../api/urlsContants";
import UserContext from "../context/UserContext";

const getDateWithTime = (dateTimeString = "27/09/2024 14:30") => {
  const dateTimeComponents = dateTimeString.split(" ");

  // Extract date components
  const dateComponents = dateTimeComponents[0].split("/");
  const yearExtracted = parseInt(dateComponents[2], 10);
  const monthExtracted = parseInt(dateComponents[1], 10) - 1; // Months are zero-based
  const dayExtracted = parseInt(dateComponents[0], 10);

  // Extract time components
  const timeComponents = dateTimeComponents[1].split(":");
  const hoursExtracted = parseInt(timeComponents[0], 10);
  const minutesExtracted = parseInt(timeComponents[1], 10);

  // Create a new Date object using the extracted components
  return new Date(
    yearExtracted,
    monthExtracted,
    dayExtracted,
    hoursExtracted,
    minutesExtracted
  );
};

const getStatus = (startDate, startTime, expiryDate, expiryTime) => {
  let currentDate = Date.now();
  let startDataTimestring = `${moment(startDate).format(
    "DD/MM/YYYY"
  )} ${startTime}}`;
  let expiryDateTimeString = `${moment(expiryDate).format(
    "DD/MM/YYYY"
  )} ${expiryTime}`;
  let currentDateTimeString = `${moment(currentDate).format(
    "DD/MM/YYYY"
  )} ${moment(currentDate).format("LT")}`;

  let meetingDate = getDateWithTime(startDataTimestring);
  let meetingExpiryDate = getDateWithTime(expiryDateTimeString);
  let currentDateAndTime = getDateWithTime(currentDateTimeString);

  if (meetingDate > currentDateAndTime) {
    return "Upcoming";
  } else if (
    meetingDate < currentDateAndTime &&
    meetingExpiryDate > currentDateAndTime
  ) {
    return "Available";
  } else {
    return "Expired";
  }
};

const TableData = styled(TableCell)(() => ({
  fontSize: "15px",
  fontWeight: 600,
  textAlign: "center",
}));

const HeadWrapper = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "10px",
  margin: "15px 0 25px 0",
}));

const Heading = styled(Typography)(({}) => ({
  color: theme.palette.primary.main,
  fontWeight: "bold",
}));

const Label = styled("label")(() => ({
  fontWeight: "bold",
  fontSize: "14px",
  marginBottom: "10px",
}));

// data format
//  {
//       courseId: { title: "Sample Course 1" },
//       link: "https://facebook.com",
//       startDate: Date.now().toLocaleString(),
//       startTime: "11:25",
//     },

const Live = () => {
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const [meetings, setMeetings] = useState([]);
  const [filteredMeetings, setFilteredMeetings] = useState([]);
  const [selectedMeeting, setSelectedMeeting] = useState("all");

  // ==================== Pageimation start ============================
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  // ====================== Pageimation end ==========================

  console.log(filteredMeetings, "---filteredMeetingsCCC");
  console.log(meetings, "---meetings");

  const getAllMeetings = async () => {
    try {
      const { data } = await get(urls.live.getAllMeetings);
      setMeetings(data.result);
      // console.log(data.result, "===data.result");
      setFilteredMeetings(data.result);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllMeetings();
  }, []);

  const handleJoinClick = (meeting) => {
    navigate(`/room/${meeting.roomId}`, {
      state: { meetingType: meeting.meetingType },
    });
  };

  const handleSelectedMeetingType = (event) => {
    setSelectedMeeting(event.target.value);
  };

  useEffect(() => {
    console.log(selectedMeeting, "===fun");
    if (selectedMeeting) {
      if (selectedMeeting === "all") {
        setFilteredMeetings(meetings);
      } else {
        setFilteredMeetings(
          meetings.filter((c) => c.meetingType === selectedMeeting)
        );
      }
    }
  }, [selectedMeeting]);

  return (
    <Container maxWidth="xl">
      <div style={{ marginTop: "20px" }}></div>
      <HeadWrapper>
        <Heading variant="h5" sx={{ fontSize: "18px" }}>
          All Upcoming Live sessions
        </Heading>

        <FormControl fullWidth sx={{ maxWidth: "300px" }}>
          <Box sx={{ display: "flex" }}>
            <FilterListIcon fontSize="small" />
            <Label id="demo-simple-select-label">Filter by meeting type</Label>
          </Box>
          <Select
            labelId="filter-live-sessions-by-course"
            id="filter-live-sessions-by-course"
            label="Courses"
            value={selectedMeeting}
            onChange={handleSelectedMeetingType}
            placeholder="Filter by meeting"
            input={
              <OutlinedInput
                // label="Category"
                sx={{
                  borderRadius: "20px",
                  textAlign: "center",
                  height: "35px",
                }}
              />
            }
          >
            <MenuItem value="all"> All </MenuItem>
            <MenuItem value="OneONoneCall">One-On-One Call </MenuItem>
            <MenuItem value="GroupCall">Group Call </MenuItem>
            <MenuItem value="LiveStreaming">Live Stream </MenuItem>
          </Select>
        </FormControl>
      </HeadWrapper>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableData>S.No</TableData>
              <TableData>Meeting Type</TableData>
              <TableData>Meeting Date</TableData>
              <TableData>Status</TableData>
              <TableData>Action</TableData>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredMeetings
              ? filteredMeetings.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell sx={{ textAlign: "center" }}>
                      {index + 1}.
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                      {row.meetingType}
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                      {`${moment(row.startDate).format("DD/MM/YYYY")} - ${
                        row.startTime
                      }`}
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                      <Box
                        sx={{
                          py: 1,
                          objectFit: "contain",
                          borderRadius: "5px",
                          background:
                            getStatus(
                              row.startDate,
                              row.startTime,
                              row.expiryDate,
                              row.expiryTime
                            ) === "Expired"
                              ? "#85848430"
                              : "#8281e74d",
                        }}
                      >
                        {getStatus(
                          row.startDate,
                          row.startTime,
                          row.expiryDate,
                          row.expiryTime
                        )}
                      </Box>
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                      <Button
                        size="small"
                        onClick={() => handleJoinClick(row)}
                        variant="contained"
                      >
                        Join
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
              : meetings.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell sx={{ textAlign: "center" }}>
                      {index + 1}.
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                      {row.meetingType}
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                      {`${moment(row.startDate).format("DD/MM/YYYY")} - ${
                        row.startTime
                      }`}
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                      <Box
                        sx={{
                          py: 1,
                          objectFit: "contain",
                          borderRadius: "5px",
                          background:
                            getStatus(
                              row.startDate,
                              row.startTime,
                              row.expiryDate,
                              row.expiryTime
                            ) === "Expired"
                              ? "#85848430"
                              : "#8281e74d",
                        }}
                      >
                        {getStatus(
                          row.startDate,
                          row.startTime,
                          row.expiryDate,
                          row.expiryTime
                        )}
                      </Box>
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                      <Button
                        size="small"
                        onClick={() => handleJoinClick(row)}
                        variant="contained"
                      >
                        Join
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
          </TableBody>
        </Table>
        {!meetings.length === 0 && (
          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: "14px",
              textAlign: "center",
              margin: "10px 0",
              py: 2,
            }}
          >
            Live sessions Not found!
          </Typography>
        )}
        {!filteredMeetings.length && (
          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: "14px",
              textAlign: "center",
              margin: "10px 0",
              py: 2,
            }}
          >
            Live sessions Not found!
          </Typography>
        )}
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={meetings.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </Container>
  );
};

export default Live;
