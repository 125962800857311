import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";

import { post, get } from "../../../api/apiMethods";
import { urls } from "../../../api/urlsContants";
import { useFormik } from "formik";
import * as Yup from "yup";
import NavbarContext from "../../../context/NavbarContext";
import { LoadingButton } from "@mui/lab";
import Laptop from "../../../assets/Images/laptop.png";
import towPiece from "../../../assets/Images/towpiece.png";
import { RippelVideoButton } from "../../RippelVideoButton";
import { theme } from "../../../theme";
import { ToastContainer, toast } from "react-toastify";

const RippelBox = styled(Box)(() => ({
  transform: "rotate(-20deg)",
}));

const Main = styled(Box)(() => ({
  height: "100vh",
  width: "100%",
  display: "flex",
  overflow: "hidden",
  justifyContent: "space-between",
}));

const LeftBox = styled(Box)(() => ({
  display: "flex",
  height: "100%",
  backgroundImage: `url(${towPiece})`,
  backgroundRepeat: "no-repeat",
  backgroundPositionY: "100%",
}));

const RightBox = styled(Box)(() => ({
  position: "relative",
  backgroundImage: `url(${Laptop})`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  backgroundPositionY: "center",
  justifyContent: "end",
}));

const inputForm = {
  width: { xs: "100%", sm: "80%", md: "80%", lg: "60%" },
  margin: "150px auto",
  padding: "0 20px",
};

const ForgotPassword = () => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const { setShowNavBar } = useContext(NavbarContext);

  useEffect(() => {
    setShowNavBar(false);

    return () => setShowNavBar(true);
  }, []);

  const formik = useFormik({
    initialValues: {
      email: "",
    },

    validationSchema: Yup.object({
      email: Yup.string().email().required().label("Email").max(150),
    }),
    onSubmit: async (values) => {
      setLoading(true);

      try {
        const body = {
          email: values.email,
        };

        const { data, status } = await post(urls.user.resetPasswordLink, body);

        if (status > 199 && status < 299) {
          toast.success("login again with new password");
          setLoading(false);
        }
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    },

    validateOnBlur: false,
  });

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();

    try {
      const res = await get(`${urls.user.resetPasswordLink}?email=${email}`);
      if (res.status > 199 && res.status < 299) {
        toast.success(
          "RESET PASSWORD LINK HAS BEEN SUCCESSFULLY SENT TO YOUR EMAIL ADDRESS!"
        );
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  return (
    <>
      <ToastContainer />
      <Main>
        <LeftBox
          sx={{
            width: { xs: "100%", sm: "100%", md: "50%", lg: "40%" },
          }}
        >
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2} sx={inputForm}>
              <Grid item xs={12} md={12}>
                <Typography variant="h5" fontWeight={600} my={2}>
                  Reset Password
                </Typography>
                <Typography
                  variant="body"
                  textAlign="center"
                  sx={{
                    fontSize: {
                      xs: "13px",
                      sm: "14px",
                      md: "16px",
                    },
                  }}
                >
                  Please enter your registered email address!
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  name="code"
                  value={email}
                  label="email"
                  variant="outlined"
                  size="small"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <LoadingButton
                  type="submit"
                  variant="contained"
                  loading={loading}
                  fullWidth
                  sx={{
                    borderRadius: "20px",
                    backgroundColor: theme.palette.primary.dark,
                  }}
                >
                  Send Reset Link
                </LoadingButton>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Link to="/" style={{ width: "100%" }}>
                  <Button type="submit" variant="outlined" fullWidth>
                    Back to Login
                  </Button>
                </Link>
              </Grid>
            </Grid>
          </form>
        </LeftBox>

        <RightBox
          sx={{
            display: { xs: "none", sm: "none", md: "block", lg: "block" },
            backgroundPositionX: { md: "center", lg: "35px" },
            width: { xs: "0", sm: "65%", md: "65%", lg: "60%" },
          }}
        >
          <RippelBox
            sx={{
              position: "absolute",
              top: { md: "42%", lg: "42%" },
              left: { md: "36%", lg: "42%" },
            }}
          >
            <RippelVideoButton />
          </RippelBox>
        </RightBox>
      </Main>
    </>
  );
};

export default ForgotPassword;
