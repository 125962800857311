import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Container,
  Typography,
  Button,
  styled,
  Paper,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { get, put } from "../../../api/apiMethods";
import { urls } from "../../../api/urlsContants";
import dayjs from "dayjs";
import React, { useState, useEffect } from "react";
import { theme } from "../../../theme";

const Heading = styled(TableCell)(() => ({
  fontWeight: "bold",
  textAlign: "center",
  fontSize: "15px",
}));
const Data = styled(TableCell)(() => ({
  textAlign: "center",
  fontSize: "15px",
}));

const Header = styled(Typography)(() => ({
  textAlign: "center",
  marginTop: "25px",
  fontWeight: "bold",
  fontSize: "1.5rem",
  color: theme.palette.primary.main,
}));

const NoDataFound = styled(Typography)(() => ({
  textAlign: "center",
  margin: "5px",
  padding: "5px",
}));

const Invoices = () => {
  const [brought, setBrought] = useState([]);
  const [loading, setLoading] = useState(false);
  // console.log(brought, "----brought");
  useEffect(() => {
    const getPaymentList = async () => {
      try {
        const response = await get(urls.user.getMyInvoices);
        setBrought(response.data.result);
      } catch (error) {
        console.log(error);
      }
    };
    getPaymentList();
  }, []);

  const handleDownload = async (id) => {
    try {
      const res = await get(`${urls.user.downloadInvoice}/${id}`, {
        responseType: "blob",
      });
      if (res) {
        const downloadUri = URL.createObjectURL(res.data);
        window.open(downloadUri, "__blank");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Container sx={{ overflow: "auto" }}>
        <Typography
          color={theme.palette.primary.main}
          sx={{
            textAlign: "center",
            marginTop: "25px",
            fontWeight: "bold",
            fontSize: "1.5rem",
          }}>
          Invoices
        </Typography>
        <br />
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <Heading>Invoice Number</Heading>
                <Heading>Date</Heading>
                <Heading>Amount</Heading>
                <Heading></Heading>
              </TableRow>
            </TableHead>
            <TableBody>
              {brought.map((invoice) => (
                <TableRow key={invoice._id} component={Paper} elevation={1}>
                  <Data>{invoice.orderId}</Data>
                  <Data>{dayjs(invoice.createdAt).format("YYYY-MM-DD")}</Data>
                  <Data>{invoice.amount}</Data>
                  <Data>
                    <LoadingButton
                      variant="contained"
                      onClick={() => handleDownload(invoice._id)}>
                      View Invoice
                    </LoadingButton>
                  </Data>
                </TableRow>
              ))}
              <TableRow>
                {!brought.length && (
                  <TableCell align="center" colSpan={12}>
                    <NoDataFound variant="h6">No data found</NoDataFound>
                  </TableCell>
                )}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </>
  );
};

export default Invoices;
