import * as React from "react";
import { ZegoUIKitPrebuilt } from "@zegocloud/zego-uikit-prebuilt";
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  styled,
} from "@mui/material";
import UserContext from "../../context/UserContext";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { urls } from "../../api/urlsContants";
import { get } from "../../api/apiMethods";
import LoadingScreen from "../customer/LoadingScreen";

const Wrapper = styled(Container)(({ theme }) => ({
  display: "flex",
  height: "90vh",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: theme.shape.borderRadius,
  overflow: "hidden",
  width: "100%",
  backgroundColor: "whitesmoke",
  marginTop: "10px",
}));

const VideoContainer = styled(Box)(() => ({
  display: "flex",
  flex: 1,
  width: "100%",
  height: "100%",
}));

const TYPE = ["OneONoneCall", "GroupCall", "LiveStreaming"];

const CREDENTIAL = {
  OneONoneCall: {
    appId: 2044416510,
    serverSecret: "be2de9bd70faf990b0b4199957ee8bbe",
  },
  GroupCall: {
    appId: 2044416510,
    serverSecret: "be2de9bd70faf990b0b4199957ee8bbe",
  },
  LiveStreaming: {
    appId: 388511714,
    serverSecret: "e98b436af7eb04f9d2d2f1173c561298",
  },
};

export default function Room() {
  const { user } = React.useContext(UserContext);
  const location = useLocation();
  const { roomID } = useParams();
  const navigate = useNavigate();
  const [isReady, setIsReady] = React.useState(false);
  const [error, setError] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [zpost, setZpost] = React.useState(null);
  const { state } = location;

  const getRoomDetails = async () => {
    try {
      const { data, status } = await get(urls.live.joinMeeting, {
        params: {
          roomID: roomID,
        },
      });
    } catch (error) {
      setError("Invalid meeting link!");
      setOpen(true);
    }
    setIsReady(true);
  };

  React.useEffect(() => {
    getRoomDetails();
  }, [roomID]);

  let myMeeting = async (element) => {
    // generate Kit Token

    let meetingType = state ? state.meetingType : TYPE[0];

    const appID = CREDENTIAL[meetingType].appId;
    const serverSecret = CREDENTIAL[meetingType].serverSecret;
    const kitToken = ZegoUIKitPrebuilt.generateKitTokenForTest(
      appID,
      serverSecret,
      roomID,
      user._id,
      user.name
    );

    // Create instance object from Kit Token.
    const zp = ZegoUIKitPrebuilt.create(kitToken);

    // start the call

    zp.joinRoom({
      container: element,
      scenario: {
        config: {
          role: ZegoUIKitPrebuilt.Audience,
        },
        mode: ZegoUIKitPrebuilt[meetingType], // To implement 1-on-1 calls, modify the parameter here to [ZegoUIKitPrebuilt.OneONoneCall].
      },
    });

    setZpost(zp);
  };

  React.useEffect(() => {
    return () => {
      if (zpost) {
        zpost.destroy();
      }
    };
  }, [zpost]);

  const handleBackPress = () => {
    navigate("/live", { replace: true });
  };

  if (!isReady)
    return <LoadingScreen message="Getting room details..., Please wait!" />;

  return (
    <>
      <Wrapper maxWidth={"xl"}>
        <VideoContainer component={"div"} ref={myMeeting}></VideoContainer>
      </Wrapper>
      <Dialog
        open={open}
        onClose={handleBackPress}
        aria-labelledby="alert-live-session-error"
        aria-describedby="alert-live-session-error-dialog-description"
      >
        <DialogTitle id="alert-live-session-error">{error}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-live-session-error-dialog-description">
            The link you are trying to access either expired || does not exist
            || you are not permitted to see the link
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button size="small" onClick={handleBackPress} autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
