import React, { useState, useEffect, useContext } from "react";
import { styled, alpha } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import InputBase from "@mui/material/InputBase";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import MoreIcon from "@mui/icons-material/MoreVert";
import Tooltip from "@mui/material/Tooltip";
import Avatar from "@mui/material/Avatar";
import { Button, ListItem } from "@mui/material";
import { blueGrey } from "@mui/material/colors";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { pages, settings, menuitems, exploreDetails } from "./NavDetails";
import Profilename from "../accountsetting/Profilename";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import logo from "../../../assets/Images/websaprucee.png";
import { urls } from "../../../api/urlsContants";
import { get } from "../../../api/apiMethods";
import UserContext from "../../../context/UserContext";
import { theme } from "../../../theme";
import NavbarContext from "../../../context/NavbarContext";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  border: "1px solid #eeeeee",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  "&:focus-within": {
    borderColor: theme.palette.primary.main,
    border: `2px solid ${theme.palette.primary.main}`,
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "auto",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  right: 0,
  [theme.breakpoints.down("sm")]: {
    display: "none",
  },
  [theme.breakpoints.down("660")]: {
    display: "none",
  },
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: blueGrey["A500"],
  fontSize: "13px",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
    [theme.breakpoints.down(660)]: {
      display: "none",
    },
  },
}));

const ExploreOptions = styled("div")(({ theme }) => ({
  position: "absolute",
  top: "100%",
  left: 0,
  backgroundColor: theme.palette.background.default,
  boxShadow: theme.shadows[2],
  padding: theme.spacing("10px"),
  zIndex: 1,
  borderRadius: "5px",
  minWidth: "200px",

  overflow: "hidden",
  marginTop: "10px",

  borderRadius: "20px",
}));

const ExploreOption = styled(Link)(({ theme }) => ({
  display: "flex",
  textDecoration: "none",
  color: theme.palette.text.primary,
  marginBottom: theme.spacing(1),
  maxWidth: "90%",
}));

const LinkButton = styled(Button)(() => ({
  my: 2,

  display: "block",
  fontFamily: "sans-serif",
  fontWeight: 550,
  textTransform: "none",
  fontSize: "13px",

  "&:hover": {
    color: theme.palette.secondary.main,
    backgroundColor: "white",
  },
}));

const Profile = styled(Avatar)(() => ({
  borderRadius: "20px",
  fontSize: "2.rem",
  backgroundColor: theme.palette.secondary.main,
  border: "1px solid lightgray",
  width: "40px",
  height: "40px",
  objectFit: "contain",
  overflow: "hidden",
}));

const UserName = styled(Typography)(() => ({
  textDecoration: "none",

  fontWeight: "bold",
  fontSize: "18px",
}));

const VALID_PATHS = [
  "/",
  "/account-settings",
  "/invoice",
  "/my-courses",
  "/refer",
  "/certificates",
  "/allcourse",
  "/live",
  "/optio2",
  "/course/material/",
  "/attendquizes",
  "/change-password",
  "/studentCheckout/",
  "/payment-failed",
  "/payment-success",
  "/course/content/",
  "/live",
  "/room/:roomId",
];

export default function CustomNavbar() {
  const { user, setUser } = useContext(UserContext);
  const [showOptions, setShowOptions] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [courses, setCourses] = React.useState([]);
  const [search, setSearch] = React.useState("");
  const [searchFilter, setSearchFilter] = React.useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const { setShowNavBar } = useContext(NavbarContext);
  const [isReadyToShow, setIsReadyToShow] = useState(false);

  React.useEffect(() => {
    if (window.localStorage.getItem("user-customer-webspruce")) {
      setShowNavBar(true);
      setIsReadyToShow(true);
    } else {
      setShowNavBar(false);
      setIsReadyToShow(false);
    }
  }, [window.localStorage.getItem("user-customer-webspruce")]);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  React.useEffect(() => {
    let user = window.localStorage.getItem("user-customer-webspruce");
    if (user) {
      setUser(JSON.parse(user));
    }
  }, []);

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
    handleMobileMenuClose();
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  useEffect(() => {
    const getCourse = async () => {
      try {
        const response = await get(urls.course.getList);
        setCourses(response.data.result.data);
      } catch (error) {
        console.log(error);
      }
    };
    getCourse();
  }, []);

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      sx={{ mt: "54px", right: "0px" }}
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      {menuitems.map((page, index) => (
        <MenuItem key={index} onClick={handleCloseNavMenu}>
          <Link
            style={{ textDecoration: "none", color: "black" }}
            to={page.path}
          >
            <ListItem
              textAlign="center"
              sx={{
                color:
                  page.path === window.location.pathname
                    ? theme.palette.primary.dark
                    : theme.palette.primary.main,
                display: "block",
                fontFamily: "sans-serif",
                fontWeight: 550,
                textTransform: "none",
                fontSize: "13px",
                textDecoration:
                  page.path === window.location.pathname ? "underline" : "none",
                "&:hover": {
                  color: theme.palette.secondary.main,
                  backgroundColor: "white",
                },
              }}
            >
              {page.name}
            </ListItem>
          </Link>
        </MenuItem>
      ))}
    </Menu>
  );

  let hoverTimeout;

  const handleMouseEnterOptions = () => {
    clearTimeout(hoverTimeout);
  };

  const handleMouseLeaveOptions = () => {
    hoverTimeout = setTimeout(() => {
      setShowOptions(false);
    }, 3000);
  };
  const handleInputChange = (e) => {
    e.stopPropagation();

    const { value } = e.target;
    setSearch(value.trim());

    if (value.trim() !== "") {
      courses.length > 0 &&
        setSearchFilter(
          courses.filter((ele) =>
            ele.title.toLowerCase().includes(value.toLowerCase().trim())
          )
        );
    } else {
      setSearchFilter([]);
    }
  };

  const handleInputClick = () => {
    setShowOptions(true);
  };

  const handleLogout = () => {
    window.localStorage.removeItem("access_token");
    window.localStorage.removeItem("user-customer-webspruce");
    window.location.href = "/";
  };

  const handleExplore = () => {
    navigate("/allcourse");
  };

  const handleOptionClick = () => {
    setShowOptions(false);
  };

  const handleNavigate = () => {
    navigate("/allcourse");
  };

  if (!isReadyToShow) return null;
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" sx={{ backgroundColor: "#fafafa" }}>
        <Toolbar sx={{ paddingLeft: "0px", paddingRight: "0px" }}>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="open drawer"
            sx={{
              mr: 2,
              mt: "5px",
              "&:hover": {
                backgroundColor: "transparent",
              },
            }}
          >
            <Box>
              <img onClick={handleNavigate} height={30} src={logo} alt="" />
            </Box>
          </IconButton>

          <Search
            onMouseEnter={handleMouseEnterOptions}
            onMouseLeave={handleMouseLeaveOptions}
            sx={{ whiteSpace: "nowrap" }}
          >
            <SearchIconWrapper>
              <SearchIcon sx={{ color: "gray" }} />
            </SearchIconWrapper>

            <StyledInputBase
              placeholder="Want to Learn ?"
              onClick={handleInputClick}
              onChange={handleInputChange}
              value={search}
            />
            {showOptions && (
              <ExploreOptions
                onMouseEnter={handleMouseEnterOptions}
                onMouseLeave={handleMouseLeaveOptions}
              >
                <Box sx={{ height: "150px", overflowY: "scroll" }}>
                  {search && courses.length > 0 ? (
                    searchFilter.length > 0 ? (
                      searchFilter.map((course) => (
                        <ExploreOption
                          key={course._id}
                          to={`/course/content/${course._id}`}
                          onClick={handleOptionClick}
                        >
                          <Typography>{course.title}</Typography>
                        </ExploreOption>
                      ))
                    ) : (
                      <Typography
                        variant="body2"
                        color="error"
                        textAlign="center"
                      >
                        No results found!
                      </Typography>
                    )
                  ) : courses.length > 0 ? (
                    courses.map((course) => (
                      <ExploreOption
                        key={course._id}
                        to={`/course/content/${course._id}`}
                        onClick={handleOptionClick}
                        sx={{
                          paddingRight: "10px",
                        }}
                      >
                        <Typography sx={{ display: "flex", flexWrap: "wrap" }}>
                          {course.title}
                        </Typography>
                      </ExploreOption>
                    ))
                  ) : (
                    <Typography
                      variant="body2"
                      color="error"
                      textAlign="center"
                    >
                      No results found!
                    </Typography>
                  )}
                </Box>
              </ExploreOptions>
            )}
          </Search>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map((page, index) => (
                <MenuItem key={index} onClick={handleCloseNavMenu}>
                  <Link style={{ textDecoration: "none" }} to={page.path}>
                    <Typography textAlign="center">{page.name}</Typography>
                  </Link>
                </MenuItem>
              ))}
            </Menu>
          </Box>

          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
              whiteSpace: "nowrap",
            }}
          >
            {pages.map((page, index) => (
              <React.Fragment key={index}>
                <Link
                  style={{ textDecoration: "none" }}
                  onClick={handleOptionClick}
                  to={page.path}
                >
                  <LinkButton
                    sx={{
                      color:
                        page.path === window.location.pathname
                          ? theme.palette.primary.dark
                          : theme.palette.primary.main,

                      textDecoration:
                        page.path === window.location.pathname
                          ? "underline"
                          : "none",
                    }}
                  >
                    {page.name}
                  </LinkButton>
                </Link>
              </React.Fragment>
            ))}
          </Box>
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: "flex" }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Profile alt="Profile-settings" src={user?.image}>
                  {user?.name?.charAt(0).toUpperCase()}
                </Profile>
              </IconButton>
            </Tooltip>
            <UserName
              variant="h6"
              color={theme.palette.primary.main}
              noWrap
              sx={{
                mr: 2,
                mt: 1,
                ml: 2,
                display: { xs: "none", md: "flex" },
              }}
            >
              {user?.name
                ? user.name.charAt(0).toUpperCase() +
                  user.name.slice(1).toLowerCase()
                : ""}
            </UserName>

            <Menu
              sx={{ mt: "54px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings
                .filter((s) => s.path !== "/logout")
                .map((setting, index) => (
                  <Link
                    key={index}
                    style={{
                      textDecoration: "none",
                      fontWeight: "bold",
                      color: "black",
                    }}
                    to={setting.path}
                  >
                    <MenuItem onClick={handleCloseUserMenu}>
                      <Typography textAlign="center">{setting.name}</Typography>
                    </MenuItem>
                  </Link>
                ))}
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
          </Box>
          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="black"
              sx={{ padding: 0 }}
            >
              <MoreIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
    </Box>
  );
}
