import React, { useContext, useEffect } from "react";
import NavbarContext from "../../../context/NavbarContext";
import { Box, Container, Grid, Typography, styled } from "@mui/material";
import { Link } from "react-router-dom";
import { theme } from "../../../theme";

const Content = styled(Typography)(() => ({
  color: "#555",
  lineHeight: "24px",
  margin: "15px 0",
  fontSize: "15px",
  textAlign: "justify",
}));

export const PrivacyPolicy = () => {
  const { setShowNavBar } = useContext(NavbarContext);

  useEffect(() => {
    setShowNavBar(false);
  }, []);

  return (
    <>
      <Box bgcolor="whitesmoke">
        <Box
          sx={{
            background: theme.palette.secondary.main,
            height: 200,
          }}>
          <Typography
            sx={{
              textAlign: "center",
              fontSize: "50px",
              fontWeight: "bold",
              color: "#000000",
              padding: "15px 0",
            }}>
            Privacy Policy
          </Typography>
          <Typography
            sx={{
              textAlign: "center",
              fontWeight: "bold",
              color: theme.palette.primary.dark,
              padding: "15px 0",
            }}>
            <Link to="/" style={{ textDecoration: "none" }}>
              Home
            </Link>{" "}
            / Privacy Policy
          </Typography>
        </Box>
        <Container>
          <Grid container py={4}>
            <Content>
              WEBSPRUCE ("we," "us," or "our") is committed to protecting your
              privacy. This Privacy Policy outlines how we collect, use,
              disclose, and safeguard your personal information when you use our
              Learning Management System Software ("LMS" or "Software"). By
              accessing or using our Software, you agree to the terms of this
              Privacy Policy. If you do not agree with our practices, please do
              not use our Software.
            </Content>
            <Grid itemxs={12} md={12} lg={12} m="10px 0">
              <ol>
                <li
                  style={{ fontSize: 20, fontWeight: 600, marginTop: "20px" }}>
                  Information We Collect
                </li>

                <Typography variant="subtitle1" mt={2}>
                  <Typography variant="span" fontWeight="bold">
                    1.1 User Information:{" "}
                  </Typography>
                  When you use our LMS, we may collect personal information such
                  as your name, email address, and other identifying
                  information.
                </Typography>
                <Typography variant="subtitle1" mt={2}>
                  <Typography variant="span" fontWeight="bold">
                    1.2 Course Data:{" "}
                  </Typography>
                  We collect data related to the courses you access or create,
                  including course content, assignments, grades, and progress.
                </Typography>
                <Typography variant="subtitle1" mt={2}>
                  <Typography variant="span" fontWeight="bold">
                    1.3 Usage Information:{" "}
                  </Typography>
                  We collect data related to the courses you access or create,
                  including course content, assignments, grades, and progress.
                </Typography>
                <li
                  style={{ fontSize: 20, fontWeight: 600, marginTop: "20px" }}>
                  How We Use Your Information
                </li>

                <Typography variant="subtitle1" mt={2}>
                  <Typography variant="span" fontWeight="bold">
                    2.1 Provide Services:{" "}
                  </Typography>
                  We use your information to provide you with access to our LMS,
                  manage courses, track progress, and facilitate communication
                  with instructors and fellow learners.
                </Typography>

                <Typography variant="subtitle1" mt={2}>
                  <Typography variant="span" fontWeight="bold">
                    2.2 Improve Services:{" "}
                  </Typography>
                  Your data helps us enhance our Software's functionality,
                  content, and user experience.
                </Typography>

                <Typography variant="subtitle1" mt={2}>
                  <Typography variant="span" fontWeight="bold">
                    2.3 Communication:{" "}
                  </Typography>
                  We may use your email address to send you important updates,
                  announcements, and information related to your courses.
                </Typography>
                <Typography variant="subtitle1" mt={2}>
                  <Typography variant="span" fontWeight="bold">
                    2.4 Customer Support:{" "}
                  </Typography>
                  We use your information to address your inquiries, resolve
                  technical issues, and provide customer support.
                </Typography>

                <li
                  style={{ fontSize: 20, fontWeight: 700, marginTop: "20px" }}>
                  Data Sharing
                </li>

                <Typography variant="subtitle1" mt={2}>
                  <Typography variant="span" fontWeight="bold">
                    3.1 Instructors:{" "}
                  </Typography>
                  Your course instructors may access your user and course data
                  to monitor your progress, provide feedback, and communicate
                  with you.
                </Typography>

                <Typography variant="subtitle1" mt={2}>
                  <Typography variant="span" fontWeight="bold">
                    3.2 Third-Party Integrations:
                  </Typography>
                  We may use third-party tools and services for analytics,
                  payment processing, or communication. These services may have
                  access to your data but will also have their own privacy
                  policies.
                </Typography>

                <Typography variant="subtitle1" mt={2}>
                  <Typography variant="span" fontWeight="bold">
                    3.3 Legal Requirements: {" s"}
                  </Typography>
                  We may disclose your information if required by law,
                  regulation, or legal process.
                </Typography>

                <li
                  style={{ fontSize: 20, fontWeight: 700, marginTop: "20px" }}>
                  Your Rights
                </li>
                <Typography variant="subtitle1" mt={2}>
                  You have the right to access, correct, or delete your personal
                  information. You can also request that we cease processing
                  your data. To exercise these rights, contact us at [your
                  contact email]. Please note that deleting your information may
                  affect your ability to use our Software.
                </Typography>

                <li
                  style={{ fontSize: 20, fontWeight: 700, marginTop: "20px" }}>
                  Cookies and Tracking
                </li>
                <Typography variant="subtitle1" mt={2}>
                  Our Software may use cookies and similar tracking technologies
                  to enhance your user experience. You can manage your cookie
                  preferences in your browser settings.
                </Typography>

                <li
                  style={{ fontSize: 20, fontWeight: 700, marginTop: "20px" }}>
                  Changes to this Policy
                </li>
                <Typography variant="subtitle1" mt={2}>
                  We may update this Privacy Policy from time to time. The "Last
                  Updated" date at the beginning of this policy will reflect the
                  latest changes. Your continued use of our Software after any
                  updates constitutes acceptance of the revised policy.
                </Typography>

                <li
                  style={{ fontSize: 20, fontWeight: 700, marginTop: "20px" }}>
                  Contact Us
                </li>
                <Typography variant="subtitle1" mt={2}>
                  If you have any questions or concerns about this Privacy
                  Policy or how we handle your data, please contact us at
                  support@webspruce.com
                </Typography>
                <Typography variant="subtitle1" mt={2}>
                  Thank you for using our Learning Management System Software.
                  We are committed to providing a secure and efficient learning
                  environment.
                </Typography>
              </ol>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};
