import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { CardMedia, Container, Grid, styled } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import { get } from "../../../api/apiMethods";
import { urls } from "../../../api/urlsContants";
import { LoadingButton } from "@mui/lab";
import { ToastContainer, toast } from "react-toastify";
import UserContext from "../../../context/UserContext";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { theme } from "../../../theme";

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ mt: 1 }}>
      <Box sx={{ width: "100%", mr: 1 }} color={theme.palette.primary.dark}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <ProgressBarBox>
        <Typography
          variant="body2"
          color={theme.palette.primary.dark}>{`${Math.round(
          props.value
        )}% Completed`}</Typography>
      </ProgressBarBox>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
};

const style = {
  heading: {
    fontWeight: "bold",
    textTransform: "uppercase",
    marginTop: "30px",
    fontSize: "19px",
  },
  name: {
    padding: "20px",
    textAlign: "center",
    fontWeight: "bold",
    color: theme.palette.primary.dark,
    borderBottom: `2px dashed ${theme.palette.primary.dark}`,
  },
  comcertificate: {
    fontSize: "13px",
  },
};

const Heading = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  textAlign: "center",
  textTransform: "uppercase",
  marginTop: "10px",
  fontSize: "19px",
  [theme.breakpoints.down("sm")]: {
    fontSize: "18px",
    textAlign: "center",
    fontWeight: "bold",
  },
}));

const Title = styled(Typography)(() => ({
  display: "-webkit-box",
  margin: "5px 0",
  WebkitLineClamp: 1,

  overflow: "hidden",
  WebkitBoxOrient: "vertical",
  color: theme.palette.primary.dark,
  fontWeight: "bold",
  fontFamily: "inter",
}));

const Complete = styled(Typography)(({ theme }) => ({
  fontSize: "13px",

  [theme.breakpoints.down("sm")]: {
    fontSize: "15px",
    display: "flex",
    justifyContent: "center",
  },
  [theme.breakpoints.down("xs")]: {
    fontSize: "15px",
    display: "flex",
    justifyContent: "center",
  },
}));
const ProgressBarBox = styled(Box)(() => ({
  alignItems: "center",
  justifyContent: "center",
  display: "flex",
}));

const MainBox = styled(Box)(() => ({
  width: { xs: "100%", md: "60%" },
  margin: "150px auto",
  textAlign: "center",
  color: "gray",
  border: `2px solid ${theme.palette.secondary.main}`,
  padding: "30px",
}));

const CardData = styled(Card)(() => ({
  marginTop: "10px",
  borderTop: `10px solid ${theme.palette.primary.dark}`,
  boxShadow: 5,
  borderRadius: "8px",
  backgroundColor: "#8EC5FC",
  background: "linear-gradient(62deg, #8EC5FC 0%, #E0C3FC 100%)",
}));

const Download = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",

  [theme.breakpoints.down("sm")]: {
    marginTop: 10,
    justifyContent: "center",
  },
  [theme.breakpoints.down("xs")]: {
    marginTop: 10,
    justifyContent: "center",
  },
}));

const TitleBox = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  gap: 2,
  marginTop: 1,
}));

const CourseText = styled(Typography)(() => ({
  textAlign: "center",
  fontSize: "13px",
  marginTop: "15px",
}));

export default function Certificate() {
  const [details, setaDetails] = React.useState([]);
  const [loading, setLoading] = React.useState("");
  const { user, setUser } = React.useContext(UserContext);
  const navigate = useNavigate();

  React.useEffect(() => {
    const getPaymentList = async () => {
      try {
        const response = await get(urls.user.getMyCourses);
        setaDetails(response.data.result);
      } catch (error) {
        console.log(error);
      }
    };
    getPaymentList();
  }, []);

  const handleDownload = async (id) => {
    setLoading(id);
    try {
      const res = await get(`${urls.user.generateCertificate}/${id}`, {
        responseType: "blob",
      });
      if (!res) {
        toast.error(res.data.message.split("_").join(" "));
      }
      if (res) {
        const downloadUri = URL.createObjectURL(res.data);
        window.open(downloadUri, "__blank");
        setLoading("");
      }
    } catch (error) {
      console.log(error);
      toast.error("COURSE NOT COMPLETED YET");
      setLoading("");
    }
  };

  return (
    <>
      <ToastContainer />
      <Container maxWidth="xl">
        <Grid container spacing={2} p={2}>
          {user.courses && user.courses.length ? null : (
            <MainBox>
              <Typography variant="h6">
                You don't have any certificates in your account! Please purchase
                a course first.
              </Typography>
              <Button
                onClick={() => navigate("/allcourse")}
                variant="contained"
                size="small"
                sx={{
                  marginTop: "30px",
                }}>
                Buy Now
              </Button>
            </MainBox>
          )}
          {user.courses
            ?.sort((a, b) => a?.title?.localeCompare(b?.title))
            .map((data, index) => (
              <Grid item xs={12} md={6} lg={4} key={index}>
                <CardData>
                  <CardMedia
                    sx={{ height: 80, width: 50 }}
                    image="images/badge.png"
                    title="green iguana"
                  />
                  <Heading>Certificate of Completion</Heading>

                  <Box sx={{ padding: 2, paddingTop: 0 }}>
                    <Typography sx={{ textAlign: "center" }}>
                      This is to certify that
                    </Typography>
                    <Typography variant="h6" sx={style.name}>
                      {user.name}
                    </Typography>
                    <CourseText>has completed the course</CourseText>
                    <TitleBox>
                      <Title variant="h6">{data.courseId?.title}</Title>
                    </TitleBox>
                  </Box>
                  <Box sx={{ width: "100%", marginTop: 1 }}>
                    <LinearProgressWithLabel value={data.completed} />
                  </Box>
                  <CardContent>
                    <Complete>
                      Complete course to unlock the certificate
                    </Complete>

                    <Download sx={{ marginTop: -2.5 }}>
                      <LoadingButton
                        size="small"
                        sx={{
                          marginTop: 0,
                          color: theme.palette.primary.dark,
                          fontWeight: "bold",
                        }}
                        onClick={() => handleDownload(data.courseId._id)}
                        loading={loading === data.courseId?._id}
                        disabled={data.completed != 100 ? true : false}>
                        Download
                      </LoadingButton>
                    </Download>
                  </CardContent>
                </CardData>
              </Grid>
            ))}
        </Grid>
      </Container>
    </>
  );
}
