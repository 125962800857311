import React from "react";
import { Navigate } from "react-router-dom";

const PublicRoute = ({ children }) => {
  const isAuth = window.localStorage.getItem("user-customer-webspruce");

  const navUser = () => {
    return <Navigate to="/allcourse" />;
  };
  return isAuth ? navUser() : children;
};
export default PublicRoute;
