import React from "react";
import { Box, TextField } from "@mui/material";

function FormInput(props) {
  const {
    label,
    name,
    type,
    formik,
    style,
    disabled,
    fullWidth = true,
    placeholder = "",
    autoComplete = "true",
    multiline,
    rows,
  } = props;

  return (
    <Box sx={[{ width: "100%" }, style]} variant="div">
      <TextField
        error={formik.touched[name] && formik.errors[name] ? true : false}
        value={formik.values[name] || (type == "number" ? Number("") : "")}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        id={name}
        disabled={disabled}
        autoComplete={(autoComplete === "true").toString()}
        name={name}
        type={type}
        fullWidth={fullWidth}
        size="small"
        label={label}
        multiline={multiline}
        rows={rows}
        helperText={formik.touched[name] ? formik.errors[name] : ""}
        placeholder={placeholder}
        sx={{
          "& .MuiInputBase-root": {
            borderRadius: "20px",
          },
        }}
      />
    </Box>
  );
}

export default FormInput;
